import { createContext, useEffect, useReducer } from "react";

// import axios from "../utils/axios";
import { isValidToken, setSession } from "../utils/jwt";
import axios from "axios";
import { config } from "../config";
import userAxiosInstance, { apiGatewayAxiosInstance } from "../utils/axios";
import { setWhiteLabelDetails } from "../redux/userManagement/userManagementSlice";

const INITIALIZE = "INITIALIZE";
const SIGN_IN = "SIGN_IN";
const SIGN_OUT = "SIGN_OUT";
const SIGN_UP = "SIGN_UP";

const initialState = {
    isAuthenticated: false,
    isInitialized: false,
    user: null,
};

let intervalID = null;

const JWTReducer = (state, action) => {
    switch (action.type) {
        case INITIALIZE:
            return {
                isAuthenticated: action.payload.isAuthenticated,
                isInitialized: true,
                user: action.payload.user,
            };
        case SIGN_IN:
            return {
                ...state,
                isAuthenticated: true,
                user: action.payload.user,
            };
        case SIGN_OUT:
            return {
                ...state,
                isAuthenticated: false,
                user: null,
            };

        case SIGN_UP:
            return {
                ...state,
                isAuthenticated: true,
                user: action.payload.user,
            };

        default:
            return state;
    }
};

const AuthContext = createContext(null);

function AuthProvider({ children }) {
    const [state, dispatch] = useReducer(JWTReducer, initialState);

    const initialize = async () => {
        try {
            const accessToken = window.sessionStorage.getItem("accessToken");
            const userId = window.sessionStorage.getItem("userId");
            const userRole = window.sessionStorage.getItem("userRole");
            if (accessToken) {
                setSession(accessToken, userId, userRole);
                const response = await apiGatewayAxiosInstance.get(`/user_management/users/get-user-by-id`);
                const { data } = response.data;

                dispatch(setWhiteLabelDetails(response.data));

                dispatch({
                    type: INITIALIZE,
                    payload: {
                        isAuthenticated: true,
                        user: data,
                    },
                });
            } else {
                dispatch({
                    type: INITIALIZE,
                    payload: {
                        isAuthenticated: false,
                        user: null,
                    },
                });
                sessionStorage.removeItem("accessToken");
                sessionStorage.removeItem("userId");
                sessionStorage.removeItem("userRole");
            }
        } catch (err) {
            console.error(err);
            dispatch({
                type: INITIALIZE,
                payload: {
                    isAuthenticated: false,
                    user: null,
                },
            });
            // sessionStorage.removeItem("accessToken");
            // sessionStorage.removeItem("userId");
            // sessionStorage.removeItem("userRole");
        }
    };
    if (!intervalID) {
        intervalID = setInterval(() => {
            initialize();
        }, 60000);

    }

    useEffect(() => {
        initialize();
    }, []);

    const signIn = async (userName, password) => {
        try {
            const response = await apiGatewayAxiosInstance.post(`/user_management/login`, {
                userName,
                password,
            });
            if (response.status === 200) {
                const { token, data } = response.data;

                setSession(token, data._id, data.roleName);
                dispatch({
                    type: SIGN_IN,
                    payload: {
                        user: data,
                    },
                });
                return true;
            } else {
                throw new Error(response);
            }
        } catch (error) {
            if (error.response) {
                throw error.response.data;
            } else {
                throw new Error("Server not reachable");
            }
        }
    };

    const signOut = async () => {
        const response = await apiGatewayAxiosInstance.post(`/user_management/logout`, {});
        setSession(null, null, null);
        dispatch({ type: SIGN_OUT });
    };

    const signUp = async (email, password, name) => {
        const response = await axios.post("/api/auth/sign-up", {
            email,
            password,
            name,
        });
        const { accessToken, user } = response.data;

        window.localStorage.setItem("accessToken", accessToken);
        dispatch({
            type: SIGN_UP,
            payload: {
                user,
            },
        });
    };

    const resetPassword = email => console.log(email);

    return (
        <AuthContext.Provider
            value={{
                ...state,
                method: "jwt",
                signIn,
                signOut,
                signUp,
                resetPassword,
            }}
        >
            {children}
        </AuthContext.Provider>
    );
}

export { AuthContext, AuthProvider };
