import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import {
    Box,
    Breadcrumbs,
    Button,
    Card,
    CardActions,
    CardContent,
    Chip,
    CircularProgress,
    FormControl,
    FormHelperText,
    Grid,
    IconButton,
    InputLabel,
    Link,
    MenuItem,
    Select,
    Tab,
    Tabs,
    TextField,
    Tooltip,
    Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { BotStatus } from "../../../../config";
import FilePreviewComponent from "../../../botBuilder/FilePreviewComponent";
import AlertMessageComponent from "../../../components/AlertMessageComponent";
import CardComponent from "../CardComponent";
import SuggestionsComponent from "../SuggestionsComponent";

export default function CreateTemplate ({ inputs }) {
    const {
        formik,
        previewImage,
        thumbnailpreviewImage,
        templateData,
        state,
        onChangeSelectBot,
        botState,
        isTempalteEditable,
        onChangeSelectTemplate,
        textMessage,
        richCardCarousel,
        richCardStandAlone,
        onSelectOrientationType,
        onSelectRichCardStandAloneCardAlignement,
        isHorizontal,
        horizontal,
        onSelectRichCardStandAloneMediaHeight,
        vertical,
        onDeleteImageRichCardStandAlone,
        onDeleteRichCardStandAloneThumbnail,
        previewFile,
        showSelectThumbnail,
        filePicekerRef,
        thumnailInputRef,
        onChangeCardTitle,
        onChangeRichCardStandAloneCardDescripton,
        handleChangeRemoveSuggestionRichCardStandAlone,
        handleChangeSuggestionRichCardStandAlone,
        CharCounter,
        setSelectedTab,
        selectedTab,
        onClickAddSuggestionRichCardStandAlone,
        handleChangeRemoveCarouselCard,
        handleChangeCarouselCardDetails,
        onClickAddSuggestionTextMessage,
        onChangeMessageContent,
        addCardRichCardCarousal,
        handleChangeRemoveSuggestionTextMessage,
        handleChangeSuggestionTextMessage,
        onCloseErrorMessage,
        onClickCancelButton,
        onSelectImage,
        cardsPreviewImageList,
        setCardsPreviewImageList,
        setCardsPreviewThumbnailImageList,
        cardsPreviewThumbnailImageList,
        onUpdatingTemplate,
        selectedBotName,
        onChangeTemplateName,
        user,
    } = inputs;

    const [aspectRatio, setAspectRatio] = useState(0);
    const [thumbnailApectRatio, setThumbnailAspectRatio] = useState(0);

    const [imageHeightRatio, setImageHeightRatio] = useState(0);
    const [imageWidthRatio, setImageWidthtRatio] = useState(0);
    const [thumbnailHeightRatio, setThumbnailHeightRatio] = useState(0);
    const [thumbnailWidthRatio, setThumbnailWidthtRatio] = useState(0);

    const ref = useRef(null);

    const scrollX = () => {
        ref.scrollX += 20;
    };
    const scrollY = () => {
        ref.scrollX -= 20;
    };

    useEffect(() => {
        if (formik.values.templateType === "rich_card") {
            if (formik.values.richCardStandAloneDetails.cardOrientation === "VERTICAL") {
                if (formik.values.richCardStandAloneDetails.mediaHeight === "SHORT_HEIGHT") {
                    setImageWidthtRatio(3);
                    setImageHeightRatio(1);
                    setThumbnailWidthtRatio(3);
                    setThumbnailHeightRatio(1);

                    setAspectRatio((3 / 1).toFixed(4));
                    setThumbnailAspectRatio((3 / 1).toFixed(4));
                } else {
                    setImageWidthtRatio(2);
                    setImageHeightRatio(1);
                    setThumbnailWidthtRatio(7);
                    setThumbnailHeightRatio(3);
                    setAspectRatio(2 / 1);
                    setThumbnailAspectRatio((7 / 3).toFixed(8));
                }
            } else {
                // Horizontal
                setImageWidthtRatio(3);
                setImageHeightRatio(4);
                setThumbnailWidthtRatio(25);
                setThumbnailHeightRatio(33);
                setAspectRatio((3 / 4).toFixed(4));
                setThumbnailAspectRatio((25 / 33).toFixed(4));
            }
        }
        if (formik.values.templateType === "carousel") {
            if (formik.values.richCardCarouselDetails.cardWidth === "MEDIUM_WIDTH") {
                if (formik.values.richCardCarouselDetails.mediaHeight === "MEDIUM_HEIGHT") {
                    setImageWidthtRatio(4);
                    setImageHeightRatio(3);
                    setAspectRatio((4 / 3).toFixed(4));

                    // setThumbnailAspectRatio(4/3);
                } else {
                    setImageWidthtRatio(2);
                    setImageHeightRatio(1);
                    setAspectRatio((2 / 1).toFixed(4));
                    // setThumbnailAspectRatio(7 / 3);
                }
            } else {
                if (formik.values.richCardCarouselDetails.mediaHeight === "MEDIUM_HEIGHT") {
                    setImageWidthtRatio(4);
                    setImageHeightRatio(5);
                    setAspectRatio((4 / 5).toFixed(4));
                    // setThumbnailAspectRatio(3 / 1);
                } else {
                    setImageWidthtRatio(5);
                    setImageHeightRatio(4);
                    setAspectRatio((5 / 4).toFixed(4));
                    // setThumbnailAspectRatio(7 / 3);
                }
            }
        }
    }, [formik.values]);

    const handleChangeMaxCharactersFields = (maxCharacters, event) => {
        const { value, name } = event.target;
        if (maxCharacters && typeof maxCharacters === "number") {
            if (value.length > maxCharacters) {
                formik.setFieldError(name, `Only ${maxCharacters} characters are allowed.`);
                return;
            }
        }
        if (name === "smsFallbackTemplateDetails.senderId") {
            formik.setFieldValue(name, value.toUpperCase());
        } else {
            formik.setFieldValue(name, value);
        }
    };

    return (
        <Grid md={8} item sx={{ backgroundColor: "transparent !important" }}>
            <Grid className='listHeader'>
                <Grid display={"flex"} alignItems={"center"}>
                    <IconButton color='secondary' onClick={onClickCancelButton} size='medium'>
                        <ArrowBackIosIcon sx={{ cursor: "pointer" }} />
                    </IconButton>
                    <Grid role='presentation'>
                        <Breadcrumbs aria-label='breadcrumb' fontSize='large'>
                            <Link
                                underline='hover'
                                color='inherit'
                                className='breadcrumbItem'
                                // href="/rcs-management/manage-bots/"
                                onClick={onClickCancelButton}
                            >
                                Templates
                            </Link>
                            <Typography className='breadcrumbItem' color='text.primary'>
                                {templateData ? `Update ${templateData.templateName}` : "Add Template"}
                            </Typography>
                        </Breadcrumbs>
                    </Grid>
                </Grid>
                <Grid>
                    <Typography variant='subtitle2' align='right'>
                        <b className='text-muted '>
                            Bot message Type : &nbsp;
                            {formik.values.botMessageType}
                        </b>
                    </Typography>
                </Grid>
            </Grid>
            <Box boxShadow={"rgba(0, 0, 0, 0.24) 0px 3px 8px"} mt={2}>
                <form onSubmit={formik.handleSubmit}>
                    <Card>
                        <CardContent>
                            <Grid>
                                <Box className='mt-1'>
                                    {!templateData && (
                                        <>
                                            <Typography className='m-1' display={"flex"}>
                                                <b className='text-dark'>Bot Name</b>
                                                <Typography color={"red"}>*</Typography>
                                            </Typography>
                                            <p className='text-dark-50 m-1'>select the bot form list below</p>

                                            <FormControl fullWidth className='m-2' error={formik.touched?.botId && formik.errors?.botId}>
                                                <InputLabel id='botType-label'>Bot List</InputLabel>
                                                <Select
                                                    disabled={state.controls.isCreateTemplateLoading}
                                                    name='BotId'
                                                    labelId='botType-label'
                                                    id='botType-label'
                                                    defaultValue={formik.values.botId}
                                                    //   defaultValue={selectedBotName}
                                                    onChange={onChangeSelectBot}
                                                    error={Boolean(formik.touched?.botId && formik.errors?.botId)}
                                                    helperText={formik.touched?.botId && formik.errors?.botId}
                                                    onBlur={formik.handleBlur}
                                                    label='Select Bot'
                                                    MenuProps={{
                                                        PaperProps: {
                                                            sx: {
                                                                boxShadow: " 0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1)",
                                                            },
                                                        },
                                                    }}
                                                >
                                                    {botState.verifiedBotList?.map((bot, index) => (
                                                        <MenuItem key={index} value={bot._id}>
                                                            {bot.botName}{" "}
                                                            <Typography variant='caption'>
                                                                Status : &nbsp;
                                                                {bot.status === BotStatus.New ? (
                                                                    <Chip
                                                                        variant='outlined'
                                                                        className='dropdownChip'
                                                                        label={bot.status.replaceAll("_", " ").toUpperCase()}
                                                                        color='primary'
                                                                        size='small'
                                                                    />
                                                                ) : bot.status === BotStatus.Development ? (
                                                                    <Chip
                                                                        variant='outlined'
                                                                        className='dropdownChip'
                                                                        label={bot.status.replaceAll("_", " ").toUpperCase()}
                                                                        color='info'
                                                                        size='small'
                                                                    />
                                                                ) : bot.status === BotStatus.VerificationInProcess ? (
                                                                    <Chip
                                                                        variant='outlined'
                                                                        className='dropdownChip'
                                                                        label={bot.status.replaceAll("_", " ").toUpperCase()}
                                                                        color='warning'
                                                                        size='small'
                                                                    />
                                                                ) : bot.status === BotStatus.Verified ? (
                                                                    <Chip
                                                                        variant='outlined'
                                                                        className='dropdownChip'
                                                                        label={bot.status.replaceAll("_", " ").toUpperCase()}
                                                                        color='success'
                                                                        size='small'
                                                                    />
                                                                ) : bot.status === BotStatus.Launched ? (
                                                                    <Chip
                                                                        variant='outlined'
                                                                        className='dropdownChip'
                                                                        label={bot.status.replaceAll("_", " ").toUpperCase()}
                                                                        color='success'
                                                                        size='small'
                                                                    />
                                                                ) : (
                                                                    <Chip
                                                                        variant='outlined'
                                                                        className='dropdownChip'
                                                                        label={bot.status.replaceAll("_", " ").toUpperCase()}
                                                                        color='warning'
                                                                        size='small'
                                                                    />
                                                                )}
                                                            </Typography>
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                                <FormHelperText>{formik.touched?.botId && formik.errors?.botId}</FormHelperText>
                                            </FormControl>
                                        </>
                                    )}

                                    <Typography display={"flex"}>
                                        <b className='text-dark m-1'>Template Name</b>
                                        <Typography color={"red"}>*</Typography>
                                    </Typography>
                                    <p className='text-dark-50 m-1'>Give your message template a name ( without spaces )</p>
                                    <TextField
                                        disabled={Boolean(
                                            !isTempalteEditable || state.controls.isCreateTemplateLoading || (isTempalteEditable && templateData),
                                        )}
                                        className='m-1'
                                        name='templateName'
                                        fullWidth
                                        label='Template Name'
                                        variant='outlined'
                                        error={Boolean(formik.touched?.templateName && formik.errors?.templateName)}
                                        helperText={formik.touched?.templateName && formik.errors?.templateName}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.templateName}
                                        onChange={e => onChangeTemplateName(e)}
                                    />
                                    <Typography display={"flex"}>
                                        <b className='text-dark m-1'>Template Type</b>
                                        <Typography color={"red"}>*</Typography>
                                    </Typography>
                                    <p className='text-dark-50 m-1'>Choose what type of message template you want to create</p>
                                    <FormControl
                                        fullWidth
                                        className='m-2'
                                        error={formik.touched?.templateType && formik.errors?.templateType}
                                        disabled={Boolean(!isTempalteEditable || state.controls.isCreateTemplateLoading)}
                                    >
                                        <InputLabel id='templatetype-label'>Template Type</InputLabel>

                                        <Select
                                            disabled={Boolean(
                                                !isTempalteEditable || state.controls.isCreateTemplateLoading || (isTempalteEditable && templateData),
                                            )}
                                            name='templateType'
                                            labelId='templatetype-label'
                                            id='templatetype-label'
                                            value={formik.values.templateType}
                                            onChange={onChangeSelectTemplate}
                                            onBlur={formik.handleBlur}
                                            error={Boolean(formik.touched?.templateType && formik.errors?.templateType)}
                                            helperText={formik.touched?.templateType && formik.errors?.templateType}
                                            label='Template Type'
                                            MenuProps={{
                                                PaperProps: {
                                                    sx: {
                                                        boxShadow: " 0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1)",
                                                    },
                                                },
                                            }}
                                        >
                                            <MenuItem value={textMessage}>Text Message</MenuItem>
                                            <MenuItem value={richCardStandAlone}>Rich Card Stand Alone</MenuItem>
                                            <MenuItem value={richCardCarousel}>Rich Card Carousel</MenuItem>
                                        </Select>
                                        <FormHelperText>{formik.touched?.templateType && formik.errors?.templateType}</FormHelperText>
                                    </FormControl>
                                    <Grid sx={{
                                        padding: "5px",
                                        backgroundColor: "snow",
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "center",
                                        placeItems: "center",
                                        placeContent: "space-between"
                                    }}>
                                        <Grid sx={{
                                            display: "flex",
                                            flexDirection: "row"
                                        }}>
                                            <Typography fontWeight={"bold"}>
                                                Note: &nbsp;&nbsp;
                                            </Typography>
                                            <Typography>
                                                To use variable in the template use square brackets [ ]. The variables within the square brackets will be replaced by the actual values provided in the CSV file. It is recommended to use Maximum 9 variables!
                                            </Typography>
                                        </Grid>
                                        <Grid >
                                            <Tooltip
                                                title={<p style={{ fontSize: ".7rem" }}>
                                                    Enjoy [DISCOUNT] off with code [CODE]!
                                                    Phone Number CODE    DISCOUNT
                                                    91XXXXXXXXX  WELCOME  10%
                                                    Enjoy 10% off with code WELCOME!
                                                </p>}
                                                className='cursor-pointer'
                                            >
                                                <InfoOutlinedIcon sx={{ opacity: "50%" }} />
                                            </Tooltip>
                                        </Grid>
                                    </Grid>

                                    {formik.values.templateType === richCardStandAlone && (
                                        <Box>
                                            <Typography display={"flex"}>
                                                <b className='text-dark m-1'>Card Orientaion</b>
                                                <Typography color={"red"}>*</Typography>
                                            </Typography>
                                            <p className='text-dark-50 m-1'>Select the card orientation you want for card</p>
                                            <FormControl
                                                fullWidth
                                                className='m-2'
                                                error={
                                                    formik.touched.richCardStandAloneDetails?.cardOrientation &&
                                                    formik.errors.richCardStandAloneDetails?.cardOrientation
                                                }
                                            >
                                                <InputLabel id='demo-simple-select-helper-label'>Select Card Orientaion</InputLabel>
                                                <Select
                                                    disabled={Boolean(!isTempalteEditable || state.controls.isCreateTemplateLoading)}
                                                    value={formik.values.richCardStandAloneDetails?.cardOrientation}
                                                    onChange={onSelectOrientationType}
                                                    label='Select Card Orientation'
                                                    labelId='demo-simple-select-helper-label'
                                                    id='demo-simple-select'
                                                    error={Boolean(
                                                        formik.touched.richCardStandAloneDetails?.cardOrientation &&
                                                            formik.errors.richCardStandAloneDetails?.cardOrientation,
                                                    )}
                                                    helperText={
                                                        formik.touched.richCardStandAloneDetails?.cardOrientation &&
                                                        formik.errors.richCardStandAloneDetails?.cardOrientation
                                                    }
                                                    onBlur={formik.handleBlur}
                                                    MenuProps={{
                                                        PaperProps: {
                                                            sx: {
                                                                boxShadow: " 0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1)",
                                                            },
                                                        },
                                                    }}
                                                >
                                                    <MenuItem value={"VERTICAL"}>VERTICAL</MenuItem>
                                                    <MenuItem value={"HORIZONTAL"}>HORIZONTAL</MenuItem>
                                                </Select>
                                                <FormHelperText>
                                                    {" "}
                                                    {formik.touched.richCardStandAloneDetails?.cardOrientation &&
                                                        formik.errors.richCardStandAloneDetails?.cardOrientation}
                                                </FormHelperText>
                                            </FormControl>
                                            {formik.values.richCardStandAloneDetails?.cardOrientation === "HORIZONTAL" ? (
                                                <Box>
                                                    <Typography display={"flex"}>
                                                        <b className='text-dark m-1'>Card Alignmemt</b>
                                                        <Typography color={"red"}>*</Typography>
                                                    </Typography>
                                                    <p className='text-dark-50 m-1'>Select the card alignment for card</p>
                                                    <FormControl
                                                        fullWidth
                                                        className='m-2'
                                                        error={formik.errors.richCardStandAloneDetails?.cardAlignment ? true : false}
                                                    >
                                                        <InputLabel id='demo-simple-select-helper-label'>Select Card Alignment*</InputLabel>
                                                        <Select
                                                            disabled={Boolean(!isTempalteEditable || state.controls.isCreateTemplateLoading)}
                                                            value={formik.values.richCardStandAloneDetails?.cardAlignment}
                                                            onChange={onSelectRichCardStandAloneCardAlignement}
                                                            label='Select Card Alignment'
                                                            labelId='demo-simple-select-helper-label'
                                                            id='demo-simple-select'
                                                            onBlur={formik.handleBlur}
                                                            error={Boolean(
                                                                formik.touched.richCardStandAloneDetails?.cardAlignment &&
                                                                    formik.errors.richCardStandAloneDetails?.cardAlignment,
                                                            )}
                                                            helperText={
                                                                formik.touched.richCardStandAloneDetails?.cardAlignment &&
                                                                formik.errors.richCardStandAloneDetails?.cardAlignment
                                                            }
                                                            MenuProps={{
                                                                PaperProps: {
                                                                    sx: {
                                                                        boxShadow:
                                                                            " 0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1)",
                                                                    },
                                                                },
                                                            }}
                                                        >
                                                            {horizontal.map((mediaType, index) => (
                                                                <MenuItem key={index} value={mediaType}>
                                                                    {mediaType}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                        <FormHelperText>
                                                            {" "}
                                                            {formik.touched.richCardStandAloneDetails?.cardAlignment &&
                                                                formik.errors.richCardStandAloneDetails?.cardAlignment}
                                                        </FormHelperText>
                                                    </FormControl>
                                                </Box>
                                            ) : (
                                                <Box>
                                                    <Typography display={"flex"}>
                                                        <b className='text-dark m-1'>Media Height</b>
                                                        <Typography color={"red"}>*</Typography>
                                                    </Typography>
                                                    <p className='text-dark-50 m-1'>Select the media height for the card</p>
                                                    <FormControl
                                                        fullWidth
                                                        className='m-2'
                                                        error={formik.errors.richCardStandAloneDetails?.mediaHeight ? true : false}
                                                    >
                                                        <InputLabel id='demo-simple-select-helper-label'>Select Media Height*</InputLabel>
                                                        <Select
                                                            disabled={Boolean(!isTempalteEditable || state.controls.isCreateTemplateLoading)}
                                                            value={formik.values.richCardStandAloneDetails?.mediaHeight}
                                                            onChange={onSelectRichCardStandAloneMediaHeight}
                                                            label='Select Media Orientaion'
                                                            labelId='demo-simple-select-helper-label'
                                                            id='demo-simple-select'
                                                            onBlur={formik.handleBlur}
                                                            error={Boolean(
                                                                formik.touched.richCardStandAloneDetails?.mediaHeight &&
                                                                    formik.errors.richCardStandAloneDetails?.mediaHeight,
                                                            )}
                                                            helperText={
                                                                formik.touched.richCardStandAloneDetails?.mediaHeight &&
                                                                formik.errors.richCardStandAloneDetails?.mediaHeight
                                                            }
                                                            MenuProps={{
                                                                PaperProps: {
                                                                    sx: {
                                                                        boxShadow:
                                                                            " 0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1)",
                                                                    },
                                                                },
                                                            }}
                                                        >
                                                            {vertical.map((mediaType, index) => (
                                                                <MenuItem key={index} value={mediaType}>
                                                                    {mediaType}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                        <FormHelperText>
                                                            {formik.touched.richCardStandAloneDetails?.mediaHeight &&
                                                                formik.errors.richCardStandAloneDetails?.mediaHeight}
                                                        </FormHelperText>
                                                    </FormControl>
                                                </Box>
                                            )}
                                            <Typography display={"flex"}>
                                                <b className='text-dark m-1'>Image/Video</b>
                                                <Typography color={"red"}>*</Typography>
                                            </Typography>
                                            <Box className='m-2'>
                                                <Box className='ml-3'>
                                                    <Box>
                                                        <Box className='preview'>
                                                            {(previewImage.url || formik.values.richCardStandAloneDetails?.media?.url) && (
                                                                <FilePreviewComponent
                                                                    fieldName={"richCardStandAloneDetails.media.url"}
                                                                    state={formik.values}
                                                                    url={previewImage.url}
                                                                    type={previewImage.type}
                                                                    previewImage={previewImage}
                                                                    deleteFile={() =>
                                                                        onDeleteImageRichCardStandAlone("richCardStandAloneDetails.media")
                                                                    }
                                                                    formikImage={formik.values.richCardStandAloneDetails?.media}
                                                                    isDisabled={Boolean(
                                                                        !isTempalteEditable || state.controls.isCreateTemplateLoading,
                                                                    )}
                                                                    aspect={aspectRatio}
                                                                    onSelectImage={onSelectImage}
                                                                    width={"300px"}
                                                                    height={"100px"}
                                                                    imageHeightRatio={imageHeightRatio}
                                                                    imageWidthRatio={imageWidthRatio}
                                                                />
                                                            )}
                                                        </Box>

                                                        {previewImage.url == null && !formik.values.richCardStandAloneDetails?.media?.url && (
                                                            <Box className='btn-container'>
                                                                <FormControl error={Boolean(formik.errors?.richCardStandAloneDetails?.media)}>
                                                                    <Button
                                                                        className='m-2 gap-3'
                                                                        type='button'
                                                                        sx={{ width: "200px" }}
                                                                        variant='outlined'
                                                                        onClick={() => filePicekerRef.current.click()}
                                                                        disabled={Boolean(
                                                                            !isTempalteEditable || state.controls.isCreateTemplateLoading,
                                                                        )}
                                                                    >
                                                                        Choose Media
                                                                    </Button>
                                                                    <input
                                                                        disabled={Boolean(
                                                                            !isTempalteEditable || state.controls.isCreateTemplateLoading,
                                                                        )}
                                                                        name='richCardStandAloneDetails.media.url'
                                                                        ref={filePicekerRef}
                                                                        accept='image/jpg,image/png,image/jpeg,image/gif,video/*'
                                                                        onChange={e =>
                                                                            previewFile(
                                                                                e.target.files[0],
                                                                                "richCardStandAloneDetails.media.url",
                                                                                "richCardStandAloneDetails.media.type",
                                                                            )
                                                                        }
                                                                        type='file'
                                                                        hidden
                                                                        onClick={e => (e.target.value = "")}
                                                                    />
                                                                    <FormHelperText>
                                                                        {formik.errors?.richCardStandAloneDetails?.media?.url}
                                                                    </FormHelperText>
                                                                </FormControl>
                                                            </Box>
                                                        )}

                                                        {formik.values.richCardStandAloneDetails?.media?.type == "video/mp4" &&
                                                            !formik.values.richCardStandAloneDetails?.thumbnail?.url && (
                                                                <Box className='btn-container'>
                                                                    <FormControl
                                                                        error={formik.errors.richCardStandAloneDetails?.thumbnail ? true : false}
                                                                    >
                                                                        <Button
                                                                            className='m-2 gap-3'
                                                                            type='button'
                                                                            variant='outlined'
                                                                            onClick={() => thumnailInputRef.current.click()}
                                                                            disabled={Boolean(
                                                                                !isTempalteEditable || state.controls.isCreateTemplateLoading,
                                                                            )}
                                                                        >
                                                                            Select Thumbnail
                                                                        </Button>
                                                                        <input
                                                                            disabled={Boolean(
                                                                                !isTempalteEditable || state.controls.isCreateTemplateLoading,
                                                                            )}
                                                                            name='richCardStandAloneDetails.thumbnail.url'
                                                                            ref={thumnailInputRef}
                                                                            accept='image/jpg,image/png,image/jpeg'
                                                                            onChange={e =>
                                                                                previewFile(
                                                                                    e.target.files[0],
                                                                                    "richCardStandAloneDetails.thumbnail.url",
                                                                                    "richCardStandAloneDetails.thumbnail.type",
                                                                                )
                                                                            }
                                                                            type='file'
                                                                            hidden
                                                                            onClick={e => (e.target.value = "")}
                                                                        />
                                                                        <FormHelperText>
                                                                            {formik?.errors?.richCardStandAloneDetails?.thumbnail}
                                                                        </FormHelperText>
                                                                    </FormControl>
                                                                </Box>
                                                            )}

                                                        <Box className='preview'>
                                                            {(thumbnailpreviewImage.url || formik.values.richCardStandAloneDetails?.thumbnail?.url) &&
                                                                formik.values.richCardStandAloneDetails?.media?.type == "video/mp4" && (
                                                                    <FilePreviewComponent
                                                                        fieldName={"richCardStandAloneDetails.thumbnail.url"}
                                                                        state={formik.values}
                                                                        url={thumbnailpreviewImage.url}
                                                                        type={thumbnailpreviewImage.type}
                                                                        previewImage={thumbnailpreviewImage}
                                                                        formikImage={formik.values.richCardStandAloneDetails?.thumbnail}
                                                                        deleteFile={() =>
                                                                            onDeleteRichCardStandAloneThumbnail(
                                                                                "richCardStandAloneDetails.thumbnail.url",
                                                                            )
                                                                        }
                                                                        isDisabled={Boolean(
                                                                            !isTempalteEditable || state.controls.isCreateTemplateLoading,
                                                                        )}
                                                                        aspect={thumbnailApectRatio}
                                                                        onSelectImage={onSelectImage}
                                                                        imageHeightRatio={thumbnailHeightRatio}
                                                                        imageWidthRatio={thumbnailWidthRatio}
                                                                    />
                                                                )}
                                                        </Box>
                                                    </Box>
                                                </Box>
                                            </Box>
                                            <Typography display={"flex"}>
                                                <b className='text-dark m-1'>Card Title</b>
                                                <Typography color={"red"}>*</Typography>
                                            </Typography>
                                            <p className='text-dark-50 m-1'>Enter the title for card</p>
                                            <TextField
                                                disabled={Boolean(!isTempalteEditable || state.controls.isCreateTemplateLoading)}
                                                className='m-2'
                                                name='cardTitle'
                                                fullWidth
                                                label='Card Title'
                                                variant='outlined'
                                                value={formik.values.richCardStandAloneDetails?.cardTitle}
                                                // onBlur={formik.handleBlur}
                                                error={Boolean(formik.errors.richCardStandAloneDetails?.cardTitle)}
                                                helperText={formik.errors.richCardStandAloneDetails?.cardTitle}
                                                onChange={onChangeCardTitle}
                                            />
                                            <React.Fragment>
                                                <Box
                                                    sx={{
                                                        p: 1,
                                                        mx: 1,
                                                        fontSize: "0.875rem",
                                                        fontWeight: "700",
                                                        display: "flex",
                                                        flexDirection: "row-reverse",
                                                    }}
                                                >
                                                    {CharCounter(formik.values?.richCardStandAloneDetails?.cardTitle)}
                                                    /200
                                                </Box>
                                            </React.Fragment>
                                            <Typography display={"flex"}>
                                                <b className='text-dark m-2'>Card Description</b>
                                                <Typography color={"red"}>*</Typography>
                                            </Typography>
                                            <p className='text-dark-50  m-1'>Enter the description for the card</p>
                                            <TextField
                                                className='m-2'
                                                fullWidth
                                                sx={{
                                                    "& legend": {
                                                        display: "none",
                                                    },
                                                    "& fieldset": {
                                                        top: 0,
                                                    },
                                                }}
                                                multiline
                                                value={formik.values.richCardStandAloneDetails.cardDescription}
                                                // onBlur={formik.handleBlur}
                                                error={Boolean(formik.errors.richCardStandAloneDetails?.cardDescription)}
                                                disabled={Boolean(!isTempalteEditable || state.controls.isCreateTemplateLoading)}
                                                helperText={formik.errors.richCardStandAloneDetails?.cardDescription}
                                                onChange={onChangeRichCardStandAloneCardDescripton}
                                                rows={4}
                                                maxRows={4}
                                                placeholder='Enter card description here'
                                            />
                                            <React.Fragment>
                                                <Box
                                                    sx={{
                                                        p: 1,
                                                        mx: 1,
                                                        fontSize: "0.875rem",
                                                        fontWeight: "700",
                                                        display: "flex",
                                                        flexDirection: "row-reverse",
                                                    }}
                                                >
                                                    {CharCounter(formik.values?.richCardStandAloneDetails?.cardDescription)}
                                                    /2000
                                                </Box>
                                            </React.Fragment>
                                            <Box>
                                                <div className='m-2 gap-3' style={{ display: "flex", alignItems: "center", marginTop: 10 }}>
                                                    {isTempalteEditable && (
                                                        <>
                                                            <Button
                                                                disabled={Boolean(
                                                                    !isTempalteEditable ||
                                                                        state.controls.isCreateTemplateLoading ||
                                                                        formik.values.richCardStandAloneDetails.suggestionsList.length >= 4,
                                                                )}
                                                                type='button'
                                                                variant='outlined'
                                                                onClick={onClickAddSuggestionRichCardStandAlone}
                                                            >
                                                                Add Button
                                                            </Button>
                                                            <Tooltip
                                                                title={<p style={{ fontSize: ".7rem" }}> Maximum 4 Suggestions.</p>}
                                                                className='cursor-pointer'
                                                            >
                                                                <InfoOutlinedIcon sx={{ opacity: "50%" }} />
                                                            </Tooltip>
                                                        </>
                                                    )}
                                                </div>

                                                <Box>
                                                    {formik.values.richCardStandAloneDetails.suggestionsList.map((suggestion, index) => (
                                                        <Box key={index} className='m-3'>
                                                            <SuggestionsComponent
                                                                handleChange={handleChangeSuggestionRichCardStandAlone}
                                                                handleRemoveSuggestion={handleChangeRemoveSuggestionRichCardStandAlone}
                                                                suggestion={suggestion}
                                                                indexOfSuggstion={index}
                                                                SuggestionErrors={
                                                                    formik.errors?.richCardStandAloneDetails?.suggestionsList &&
                                                                    formik.errors?.richCardStandAloneDetails?.suggestionsList[index]
                                                                }
                                                                SuggestionTouched={
                                                                    formik.touched?.richCardStandAloneDetails?.suggestionsList &&
                                                                    formik.touched?.richCardStandAloneDetails?.suggestionsList[index]
                                                                }
                                                                SuggestionBlur={formik.handleBlur}
                                                                editable={isTempalteEditable}
                                                                isDisabled={Boolean(!isTempalteEditable || state.controls.isCreateTemplateLoading)}
                                                            />
                                                        </Box>
                                                    ))}
                                                </Box>
                                            </Box>
                                        </Box>
                                    )}

                                    {formik.values.templateType === richCardCarousel && (
                                        <>
                                            <Box fullWidth>
                                                <FormControl
                                                    fullWidth
                                                    className='m-2'
                                                    // error={cardErrors?.cardWidth ? true : false}
                                                >
                                                    <InputLabel id='card-width-menu'>Select Card Width*</InputLabel>
                                                    <Select
                                                        name='cardWidth'
                                                        labelId='card-width-menu'
                                                        id='demo-simple-select'
                                                        value={formik.values.richCardCarouselDetails?.cardWidth}
                                                        onChange={e =>
                                                            handleChangeCarouselCardDetails({ field: e.target.name, value: e.target.value })
                                                        }
                                                        label='Select Card Width'
                                                        error={Boolean(formik.errors.richCardCarouselDetails?.cardWidth)}
                                                        // helperText={
                                                        //     "asdfghjk"
                                                        // }
                                                        disabled={!Boolean(isTempalteEditable || state.controls.isCreateTemplateLoading)}
                                                        onBlur={formik.handleBlur}
                                                        MenuProps={{
                                                            PaperProps: {
                                                                sx: {
                                                                    boxShadow: " 0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1)",
                                                                },
                                                            },
                                                        }}
                                                    >
                                                        <MenuItem value={"SMALL_WIDTH"}>SMALL</MenuItem>
                                                        <MenuItem value={"MEDIUM_WIDTH"}>MEDIUM</MenuItem>
                                                    </Select>
                                                </FormControl>

                                                <FormControl
                                                    fullWidth
                                                    className='m-2'
                                                    // error={cardErrors?.mediaHeight ? true : false}
                                                >
                                                    <InputLabel id='demo-simple-select-helper-label'>Select Media Height*</InputLabel>
                                                    <Select
                                                        name='mediaHeight'
                                                        value={formik.values.richCardCarouselDetails?.mediaHeight}
                                                        label='Select Media Height'
                                                        labelId='demo-simple-select-helper-label'
                                                        id='demo-simple-select'
                                                        onChange={e =>
                                                            handleChangeCarouselCardDetails({ field: e.target.name, value: e.target.value })
                                                        }
                                                        disabled={!Boolean(isTempalteEditable || state.controls.isCreateTemplateLoading)}
                                                        onBlur={formik.handleBlur}
                                                        // error={Boolean(
                                                        //     mediaHeight && mediaHeight
                                                        // )}
                                                        // helperText={
                                                        //     mediaHeight && mediaHeight
                                                        // }

                                                        MenuProps={{
                                                            PaperProps: {
                                                                sx: {
                                                                    boxShadow: " 0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1)",
                                                                },
                                                            },
                                                        }}
                                                    >
                                                        <MenuItem value={"SHORT_HEIGHT"}>SHORT</MenuItem>
                                                        <MenuItem value={"MEDIUM_HEIGHT"}>MEDIUM</MenuItem>
                                                    </Select>
                                                </FormControl>
                                                {isTempalteEditable && (
                                                    <>
                                                        <div
                                                            className='gap-3'
                                                            style={{
                                                                textAlign: "end",
                                                            }}
                                                        >
                                                            <Button
                                                                type='button'
                                                                variant='outlined'
                                                                onClick={addCardRichCardCarousal}
                                                                style={{
                                                                    marginRight: 10,
                                                                    marginTop: 10,
                                                                }}
                                                            >
                                                                Add Card
                                                            </Button>
                                                            <hr />
                                                        </div>
                                                    </>
                                                )}
                                            </Box>
                                            <Box fullWidth sx={{ overflow: "scroll" }}>
                                                <Tabs
                                                    value={selectedTab}
                                                    onChange={(event, newValue) => setSelectedTab(newValue)}
                                                    TabIndicatorProps={{
                                                        sx: {
                                                            display: "none",
                                                            backgroundColor: "#000000",
                                                        },
                                                    }}
                                                    textColor='primary'
                                                    centered
                                                    sx={{ gap: 5, width: "max-content", overflow: "auto" }}
                                                >
                                                    {formik.values.richCardCarouselDetails?.cardsList.map((card, index) => (
                                                        <Tab
                                                            key={index}
                                                            label={`Card ${index + 1}`}
                                                            // label={<span>{`Card ${index + 1}`} <IconButton size="small" onClick={() => { handle() }}>
                                                            //     <CloseIcon /></IconButton></span>
                                                            // }
                                                            sx={{
                                                                border: 2,
                                                                borderRadius: 3,
                                                                mr: 2,
                                                                outline: "0px",
                                                            }}
                                                        />
                                                    ))}
                                                </Tabs>
                                            </Box>
                                            <Box>
                                                {formik.values.richCardCarouselDetails?.cardsList.map((card, index) => (
                                                    <div key={`richCardCarouselDetails_${index}`} hidden={selectedTab !== index}>
                                                        <Box
                                                            className='rounded mt-3'
                                                            sx={{
                                                                boxShadow: "1px 1px 3px rgb(209 213 219), -1px -1x -3px rgb(156 163 175)",
                                                            }}
                                                        >
                                                            <CardComponent
                                                                cardWidth={formik.values.richCardCarouselDetails.cardWidth}
                                                                formik={formik}
                                                                mediaHeight={formik.values.richCardCarouselDetails.mediaHeight}
                                                                handleChange={handleChangeCarouselCardDetails}
                                                                handleRemoveCard={handleChangeRemoveCarouselCard}
                                                                cardErrors={formik.errors?.richCardCarouselDetails?.cardsList[index]}
                                                                cardTouched={formik.touched?.richCardCarouselDetails?.cardsList[index]}
                                                                cardHandleBlur={formik.handleBlur}
                                                                data={card}
                                                                index={index}
                                                                cardCount={formik.values.richCardCarouselDetails?.cardsList.length}
                                                                disabled={Boolean(isTempalteEditable || state.controls.isCreateTemplateLoading)}
                                                                cardsPreviewImageList={cardsPreviewImageList}
                                                                setCardsPreviewImageList={setCardsPreviewImageList}
                                                                setCardsPreviewThumbnailImageList={setCardsPreviewThumbnailImageList}
                                                                cardsPreviewThumbnailImageList={cardsPreviewThumbnailImageList}
                                                                aspectRatio={aspectRatio}
                                                                imageHeightRatio={imageHeightRatio}
                                                                imageWidthRatio={imageWidthRatio}
                                                            />
                                                        </Box>
                                                    </div>
                                                ))}
                                            </Box>
                                        </>
                                    )}

                                    {formik.values.templateType === textMessage && (
                                        <Box>
                                            <Typography display={"flex"}>
                                                <b className='text-dark m-2'>Message Content</b>
                                                <Typography color={"red"}>*</Typography>
                                            </Typography>
                                            <p className='text-dark m-1'>Enter the content for the message here</p>
                                            <TextField
                                                // name="textMessageDetails.messageContent"
                                                className='m-2'
                                                fullWidth
                                                sx={{
                                                    "& legend": {
                                                        display: "none",
                                                    },
                                                    "& fieldset": {
                                                        top: 0,
                                                    },
                                                }}
                                                multiline
                                                rows={4}
                                                maxRows={4}
                                                placeholder='Enter text message here'
                                                error={Boolean(
                                                    // formik.touched
                                                    //     .textMessageDetails
                                                    //     ?.messageContent &&
                                                    formik.errors.textMessageDetails?.messageContent,
                                                )}
                                                helperText={
                                                    // formik.touched
                                                    //     .textMessageDetails
                                                    //     ?.messageContent &&
                                                    formik.errors.textMessageDetails?.messageContent
                                                }
                                                onBlur={formik.handleBlur}
                                                disabled={Boolean(!isTempalteEditable || state.controls.isCreateTemplateLoading)}
                                                value={formik.values.textMessageDetails.messageContent}
                                                onChange={onChangeMessageContent}
                                            />

                                            <React.Fragment>
                                                <Box
                                                    sx={{
                                                        p: 1,
                                                        mx: 1,
                                                        fontSize: "0.875rem",
                                                        fontWeight: "700",
                                                        display: "flex",
                                                        flexDirection: "row-reverse",
                                                    }}
                                                >
                                                    {CharCounter(formik.values?.textMessageDetails?.messageContent)}
                                                    /2500
                                                </Box>
                                            </React.Fragment>
                                            <Box>
                                                <div
                                                    className='m-2 gap-3 allign-right'
                                                    style={{ display: "flex", alignItems: "center", marginTop: 10 }}
                                                >
                                                    {isTempalteEditable && (
                                                        <>
                                                            <Button
                                                                type='button'
                                                                variant='outlined'
                                                                onClick={onClickAddSuggestionTextMessage}
                                                                disabled={formik.values.textMessageDetails?.suggestionsList?.length >= 11}
                                                            >
                                                                Add Button
                                                            </Button>

                                                            <Tooltip
                                                                title={<p style={{ fontSize: ".7rem" }}> You can add maximum 11 buttons.</p>}
                                                                className='cursor-pointer'
                                                            >
                                                                <InfoOutlinedIcon sx={{ opacity: "50%" }} />
                                                            </Tooltip>
                                                        </>
                                                    )}
                                                </div>

                                                <Box>
                                                    {formik.values.textMessageDetails?.suggestionsList.map((suggestion, index) => (
                                                        <Box key={index} className='m-3'>
                                                            <SuggestionsComponent
                                                                handleChange={handleChangeSuggestionTextMessage}
                                                                handleRemoveSuggestion={handleChangeRemoveSuggestionTextMessage}
                                                                suggestion={suggestion}
                                                                indexOfSuggstion={index}
                                                                formik={formik}
                                                                SuggestionErrors={
                                                                    formik.errors?.textMessageDetails?.suggestionsList &&
                                                                    formik.errors?.textMessageDetails?.suggestionsList[index]
                                                                }
                                                                SuggestionTouched={formik.touched?.textMessageDetails?.suggestionsList[index]}
                                                                SuggestionBlur={formik.handleBlur}
                                                                editable={isTempalteEditable}
                                                                isDisabled={Boolean(!isTempalteEditable || state.controls.isCreateTemplateLoading)}
                                                            />
                                                        </Box>
                                                    ))}
                                                </Box>
                                            </Box>
                                        </Box>
                                    )}

                                    <hr />
                                    <Grid container padding={2}>
                                        <FormControl>
                                            <Box item display={"flex"} alignItems={"center"} gap={3}>
                                                <input
                                                    type='checkbox'
                                                    name='isSMSFallbackRequired'
                                                    onChange={formik.handleChange}
                                                    value={formik.values.isSMSFallbackRequired ? true : false}
                                                    checked={formik.values.isSMSFallbackRequired}
                                                    disabled={Boolean(!isTempalteEditable || state.controls.isCreateTemplateLoading)}
                                                />
                                                <Typography className='subHeadings'>SMS fallback template</Typography>
                                            </Box>
                                        </FormControl>
                                    </Grid>
                                    {formik.values.isSMSFallbackRequired && (
                                        <Grid padding={2} borderRadius='5px' boxShadow={"rgba(99, 99, 99, 0.2) 0px 2px 8px 0px !important"}>
                                            <Typography className='text-dark'>Fallback Credentials</Typography>
                                            <Grid>
                                                <Grid container>
                                                    <Grid item padding={2} md={6}>
                                                        <Typography className='subHeadings' paddingY={1} variant='h6' fontStyle={"bold"}>
                                                            Sender Id
                                                        </Typography>
                                                        <Typography className='text-muted mb-2'>
                                                            Sender ID should be 6 alpha characters or numeric.
                                                        </Typography>
                                                        {/* <TextField fullWidth size='small' name="smsFallbackTemplateDetails.senderId" label={null} variant="outlined"
                                                            // InputProps={fontSize}
                                                            mb={4}
                                                            disabled={Boolean(
                                                                (!isTempalteEditable ||
                                                                    state.controls
                                                                        .isCreateTemplateLoading)
                                                            )}
                                                            value={formik.values.smsFallbackTemplateDetails.senderId}
                                                            //  disabled={isSubmitting || operation === 'view'}
                                                            error={Boolean(formik.errors.smsFallbackTemplateDetails && formik.errors.smsFallbackTemplateDetails.senderId)}
                                                            helperText={formik.errors.smsFallbackTemplateDetails && formik.errors.smsFallbackTemplateDetails.senderId}
                                                            // onChange={formik.handleChange}
                                                            onChange={(event) => handleChangeMaxCharactersFields(6, event)}
                                                            sx={{
                                                                '& legend': { display: 'none' }, '& fieldset': { top: 0 }
                                                            }}
                                                        /> */}
                                                        {/* {user && user.roleName.toLowerCase() !== ERoles.SUPER_ADMIN && */}
                                                        <Select
                                                            name='smsFallbackTemplateDetails.senderId'
                                                            id='smsFallbackTemplateDetails.senderId'
                                                            // label='Sender ID'
                                                            sx={{ minWidth: "100%" }}
                                                            error={Boolean(
                                                                formik.errors.smsFallbackTemplateDetails &&
                                                                    formik.errors.smsFallbackTemplateDetails.senderId,
                                                            )}
                                                            helperText={
                                                                formik.errors.smsFallbackTemplateDetails &&
                                                                formik.errors.smsFallbackTemplateDetails.senderId
                                                            }
                                                            onChange={formik.handleChange}
                                                            MenuProps={{
                                                                PaperProps: {
                                                                    sx: {
                                                                        boxShadow:
                                                                            " 0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1)",
                                                                    },
                                                                },
                                                            }}
                                                            disabled={Boolean(!isTempalteEditable || state.controls.isCreateTemplateLoading)}
                                                            // defaultValue={"TXT"}
                                                            value={formik.values?.smsFallbackTemplateDetails?.senderId}
                                                        >
                                                            {user?.orgDetails?.senderIds?.map((senderId) => {

                                                                return (<MenuItem key={senderId} value={senderId}>
                                                                    {senderId}
                                                                </MenuItem>)
                                                            })}


                                                            {!(user?.orgDetails?.senderIds.includes(formik.values?.smsFallbackTemplateDetails?.senderId)) && formik.values?.smsFallbackTemplateDetails?.senderId &&

                                                                <MenuItem key={formik.values?.smsFallbackTemplateDetails?.senderId} value={formik.values?.smsFallbackTemplateDetails?.senderId}>
                                                                    {formik.values?.smsFallbackTemplateDetails?.senderId}
                                                                </MenuItem>
                                                            }
                                                        </Select>
                                                    </Grid>
                                                    <Grid item padding={2} md={6}>
                                                        <Typography className='subHeadings' paddingY={1} variant='h6' fontStyle={"bold"}>
                                                            Message Type
                                                        </Typography>
                                                        <Typography className='text-muted mb-2'>
                                                            Message Type should be defined as per the type of messaging: TXT for English and UNI for
                                                            Unicode messaging.
                                                        </Typography>

                                                        <FormControl fullWidth>
                                                            <InputLabel id='smsFallbackTemplateDetails.messageType'>Message Type</InputLabel>
                                                            <Select
                                                                name='smsFallbackTemplateDetails.messageType'
                                                                id='smsFallbackTemplateDetails.messageType'
                                                                label='Message Type'
                                                                error={Boolean(
                                                                    formik.errors.smsFallbackTemplateDetails &&
                                                                        formik.errors.smsFallbackTemplateDetails.messageType,
                                                                )}
                                                                helperText={
                                                                    formik.errors.smsFallbackTemplateDetails &&
                                                                    formik.errors.smsFallbackTemplateDetails.messageType
                                                                }
                                                                onChange={formik.handleChange}
                                                                MenuProps={{
                                                                    PaperProps: {
                                                                        sx: {
                                                                            boxShadow:
                                                                                " 0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1)",
                                                                        },
                                                                    },
                                                                }}
                                                                disabled={Boolean(!isTempalteEditable || state.controls.isCreateTemplateLoading)}
                                                                // defaultValue={"TXT"}
                                                                value={formik.values?.smsFallbackTemplateDetails?.messageType}
                                                            >
                                                                <MenuItem key={"txt"} value={"TXT"}>
                                                                    Text
                                                                </MenuItem>
                                                                <MenuItem key={"uni"} value={"UNI"}>
                                                                    Unicode
                                                                </MenuItem>
                                                            </Select>
                                                        </FormControl>
                                                        {/* <Typography className='subHeadings' paddingY={1} variant="h6" fontStyle={"bold"}>API Key</Typography>
                                                        <Typography className="text-muted mb-2">
                                                            Enter your SMS panel api key.
                                                        </Typography>
                                                        <TextField fullWidth size='small' name="smsFallbackTemplateDetails.apiKey" label={null} variant="outlined"
                                                            // InputProps={fontSize}
                                                            mb={4}
                                                            disabled={Boolean(
                                                                (!isTempalteEditable ||
                                                                    state.controls
                                                                        .isCreateTemplateLoading)
                                                            )}
                                                            value={formik.values.smsFallbackTemplateDetails.apiKey}
                                                            //  disabled={isSubmitting || operation === 'view'}
                                                            error={Boolean(formik.errors.smsFallbackTemplateDetails && formik.errors.smsFallbackTemplateDetails.apiKey)}
                                                            helperText={formik.errors.smsFallbackTemplateDetails && formik.errors.smsFallbackTemplateDetails.apiKey}
                                                            onChange={formik.handleChange}
                                                            sx={{
                                                                '& legend': { display: 'none' }, '& fieldset': { top: 0 }
                                                            }}
                                                        /> */}
                                                    </Grid>
                                                </Grid>
                                                <Grid container>
                                                    <Grid item padding={2} md={6}>
                                                        <Typography className='subHeadings' paddingY={1} variant='h6' fontStyle={"bold"}>
                                                            DLT Entity Id
                                                        </Typography>
                                                        <Typography className='text-muted mb-2'>
                                                            This parameter can be used to pass the DLT Entity ID for your Message.
                                                        </Typography>
                                                        <TextField
                                                            fullWidth
                                                            size='small'
                                                            name='smsFallbackTemplateDetails.dltEntityId'
                                                            label={null}
                                                            variant='outlined'
                                                            // InputProps={fontSize}
                                                            mb={4}
                                                            disabled={Boolean(!isTempalteEditable || state.controls.isCreateTemplateLoading)}
                                                            value={formik.values.smsFallbackTemplateDetails.dltEntityId}
                                                            //  disabled={isSubmitting || operation === 'view'}
                                                            error={Boolean(
                                                                formik.errors.smsFallbackTemplateDetails &&
                                                                    formik.errors.smsFallbackTemplateDetails.dltEntityId,
                                                            )}
                                                            helperText={
                                                                formik.errors.smsFallbackTemplateDetails &&
                                                                formik.errors.smsFallbackTemplateDetails.dltEntityId
                                                            }
                                                            onChange={formik.handleChange}
                                                            sx={{
                                                                "& legend": { display: "none" },
                                                                "& fieldset": { top: 0 },
                                                            }}
                                                        />
                                                    </Grid>
                                                    <Grid item padding={2} md={6}>
                                                        <Typography className='subHeadings' paddingY={1} variant='h6' fontStyle={"bold"}>
                                                            DLT Template Id
                                                        </Typography>
                                                        <Typography className='text-muted mb-2'>
                                                            This parameter can be used to pass the DLT Template ID for your Message.
                                                        </Typography>
                                                        <TextField
                                                            fullWidth
                                                            size='small'
                                                            name='smsFallbackTemplateDetails.dltTempId'
                                                            label={null}
                                                            variant='outlined'
                                                            // InputProps={fontSize}
                                                            mb={4}
                                                            disabled={Boolean(!isTempalteEditable || state.controls.isCreateTemplateLoading)}
                                                            value={formik.values.smsFallbackTemplateDetails.dltTempId}
                                                            //  disabled={isSubmitting || operation === 'view'}
                                                            error={Boolean(
                                                                formik.errors.smsFallbackTemplateDetails &&
                                                                    formik.errors.smsFallbackTemplateDetails.dltTempId,
                                                            )}
                                                            helperText={
                                                                formik.errors.smsFallbackTemplateDetails &&
                                                                formik.errors.smsFallbackTemplateDetails.dltTempId
                                                            }
                                                            onChange={formik.handleChange}
                                                            sx={{
                                                                "& legend": { display: "none" },
                                                                "& fieldset": { top: 0 },
                                                            }}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid container>
                                                {/* <Grid item padding={2} md={6}>
                                                    <Typography className='subHeadings' paddingY={1} variant='h6' fontStyle={"bold"}>
                                                        Message Type
                                                    </Typography>
                                                    <Typography className='text-muted mb-2'>
                                                        Message Type should be defined as per the type of messaging: TXT for English and UNI for
                                                        Unicode messaging.
                                                    </Typography>

                                                    <FormControl fullWidth>
                                                        <InputLabel id='smsFallbackTemplateDetails.messageType'>Message Type</InputLabel>
                                                        <Select
                                                            name='smsFallbackTemplateDetails.messageType'
                                                            id='smsFallbackTemplateDetails.messageType'
                                                            label='Message Type'
                                                            error={Boolean(
                                                                formik.errors.smsFallbackTemplateDetails &&
                                                                formik.errors.smsFallbackTemplateDetails.messageType,
                                                            )}
                                                            helperText={
                                                                formik.errors.smsFallbackTemplateDetails &&
                                                                formik.errors.smsFallbackTemplateDetails.messageType
                                                            }
                                                            onChange={formik.handleChange}
                                                            MenuProps={{
                                                                PaperProps: {
                                                                    sx: {
                                                                        boxShadow:
                                                                            " 0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1)",
                                                                    },
                                                                },
                                                            }}
                                                            disabled={Boolean(!isTempalteEditable || state.controls.isCreateTemplateLoading)}
                                                            // defaultValue={"TXT"}
                                                            value={formik.values?.smsFallbackTemplateDetails?.messageType}
                                                        >
                                                            <MenuItem key={"txt"} value={"TXT"}>
                                                                Text
                                                            </MenuItem>
                                                            <MenuItem key={"uni"} value={"UNI"}>
                                                                Unicode
                                                            </MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </Grid> */}
                                                <Grid item padding={2} md={6}>
                                                    <Typography className='subHeadings' paddingY={1} variant='h6' fontStyle={"bold"}>
                                                        Message
                                                    </Typography>
                                                    <Typography className='text-muted mb-2'>
                                                        Message should be no longer than 2000 characters for English Messaging and for Unicode it
                                                        should be 750 characters.
                                                    </Typography>
                                                    <TextField
                                                        fullWidth
                                                        size='small'
                                                        name='smsFallbackTemplateDetails.message'
                                                        label={null}
                                                        variant='outlined'
                                                        // InputProps={fontSize}
                                                        mb={4}
                                                        disabled={Boolean(!isTempalteEditable || state.controls.isCreateTemplateLoading)}
                                                        value={formik.values.smsFallbackTemplateDetails.message}
                                                        //  disabled={isSubmitting || operation === 'view'}
                                                        error={Boolean(
                                                            formik.errors.smsFallbackTemplateDetails &&
                                                                formik.errors.smsFallbackTemplateDetails.message,
                                                        )}
                                                        helperText={
                                                            formik.errors.smsFallbackTemplateDetails &&
                                                            formik.errors.smsFallbackTemplateDetails.message
                                                        }
                                                        // onChange={formik.handleChange}
                                                        onChange={event =>
                                                            formik.values.messageType === "TXT"
                                                                ? handleChangeMaxCharactersFields(2000, event)
                                                                : handleChangeMaxCharactersFields(750, event)
                                                        }
                                                        sx={{
                                                            "& legend": { display: "none" },
                                                            "& fieldset": { top: 0 },
                                                        }}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    )}
                                </Box>
                            </Grid>
                        </CardContent>
                        <CardActions>
                            <Grid container sx={{ alignItems: "center" }}>
                                <Grid item>
                                    {templateData ? (
                                        <></>
                                    ) : state.controls.isCreateTemplateLoading ? (
                                        <Button variant='outlined'>
                                            <CircularProgress size={20} className='spinner' />
                                            Creating Template..
                                        </Button>
                                    ) : (
                                        <Button
                                            disabled={Boolean(!isTempalteEditable || state.controls.isCreateTemplateLoading)}
                                            type='submit'
                                            variant='contained'
                                            className='m-2'
                                        >
                                            Create Template
                                        </Button>
                                    )}
                                    {isTempalteEditable && templateData && (
                                        <Button
                                            disabled={Boolean(!isTempalteEditable || state.controls.isCreateTemplateLoading)}
                                            onClick={() => onUpdatingTemplate()}
                                            variant='contained'
                                            className='m-2'
                                        >
                                            Update Template
                                        </Button>
                                    )}
                                </Grid>
                                <Grid item>
                                    <Button
                                        disabled={Boolean(state.controls.isCreateTemplateLoading)}
                                        variant='contained'
                                        color='error'
                                        onClick={onClickCancelButton}
                                        className='m-2'
                                    >
                                        Cancel
                                    </Button>
                                </Grid>
                            </Grid>
                        </CardActions>
                        {state.controls.addNewTemplateErrorMessage && (
                            <AlertMessageComponent
                                message={state.controls.addNewTemplateErrorMessage}
                                fieldName={"addNewTemplateErrorMessage"}
                                handleClose={onCloseErrorMessage}
                                show={Boolean(state.controls.addNewTemplateErrorMessage)}
                            />
                        )}
                        {state.controls.updateTemplateErrorMessage && (
                            <AlertMessageComponent
                                message={state.controls.updateTemplateErrorMessage}
                                fieldName={"updateTemplateErrorMessage"}
                                handleClose={onCloseErrorMessage}
                                show={Boolean(state.controls.updateTemplateErrorMessage)}
                            />
                        )}
                    </Card>
                </form>
            </Box>
        </Grid>
    );
}
