import AccessTimeIcon from "@mui/icons-material/AccessTime";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Breadcrumbs, Button, Card, CardContent, CircularProgress, FormControl, Grid, IconButton, InputLabel, Link, MenuItem, Paper, Select, styled, Table, TableBody, TableCell, tableCellClasses, TableContainer, TableHead, TableRow, TextField, Tooltip, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import AodIcon from "@mui/icons-material/Aod";

import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
import UnpublishedIcon from "@mui/icons-material/Unpublished";
import { useDispatch, useSelector } from 'react-redux';
import RcsDeliveredIcon from "../../../assets/icons/rcs-delivered-icon";
import RcsReadIcon from "../../../assets/icons/rcs-read-icon";
import RcsSentIcon from "../../../assets/icons/rcs-sent-icon";
import RCSLogo from "../../../assets/icons/RCSLogo.png";
import SmsLogo from "../../../assets/icons/smsLogo.png";
import { getColourByMessageStatus, getStatusToDisplay, messageLastStatus, MessageStatus } from '../../../config';
import { setApiBotStatusReport, setApiBotStatusReportLoading } from '../../../redux/apiReportManagement/apiReportManagementSlice';
import { apiGatewayAxiosInstance } from '../../../utils/axios';
import TablePaginationShowPage from '../../../utils/tablePagination';
import FetchingSpinner from '../../components/FetchingSpinner';
import NoRecords from '../../components/NoRecords';
import CampaignSimulator from '../simulatorComponents/ReportSimulator';

import { Cached, Download } from "@mui/icons-material";
import ChevronLeftRoundedIcon from "@mui/icons-material/ChevronLeftRounded";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import FirstPageRoundedIcon from "@mui/icons-material/FirstPageRounded";
import LastPageRoundedIcon from "@mui/icons-material/LastPageRounded";
import { tooltipClasses } from '@mui/joy';
import moment from 'moment/moment';
import AlertMessageComponent from "../../components/AlertMessageComponent";


const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        fontSize: "14px !important",
        fontWeight: "bold",
        backgroundColor: "#f4f4f4",
        padding: "5px 15px !important",
        // color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: "13px !important",
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    // "&:nth-of-type(odd)": {
    //     backgroundColor: theme.palette.action.hover,
    // },

    "&:last-child td, &:last-child th": {
        border: 0,
    },
}));

const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
        color: theme.palette.common.black,
    },
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.black,
    },
}));

const calculateTime = value => {
    const time = new Date(value).toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
    });
    const day = new Date(value).toLocaleDateString([], { day: "2-digit" });
    const month = new Date(value).toLocaleDateString([], { month: "2-digit" });
    const year = new Date(value).toLocaleDateString([], { year: "numeric" });

    return `${day}-${month}-${year} , ${time}`;
};


const MessageStatusForCount = {
    Failed: 'FAILED',
    Read: 'READ',
    Delivered: 'DELIVERED',
    Sent: 'SENT',
    Retry: 'RETRY',
    Pending: 'PENDING',
    TTLExpirationRevoked: 'TTL_EXPIRATION_REVOKED',
    Sms: 'SMS',
    TotalCount: 'TOTAL_COUNT',
    CampaignLastTime: 'CAMPAIGN_LAST_TIME',
    CampaignFirstTime: 'CAMPAIGN_FIRST_TIME',
    TotalSMSCount: 'TOTAL_SMS',
    SmsDELIVERED: 'SMS-DELIVERED',
    TotalSMSFailed: 'TOTAL_SMS_FAILED'

}


export default function ViewBotApiReport({ selectedBot, setShowBotReport }) {


    const [totalCountPageSize, setTotalCountPageSize] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [currentPage, setCurrentPage] = useState(0);
    const [apiBotReportListLoader, setApiBotReportListLoader] = useState(false);
    const [apiBotReportList, setApiBotReportList] = useState([]);
    const [showApiBotSimulator, setShowApiBotSimulator] = useState(false);
    const [apiBotMessageDetails, setApiBotMessageDetails] = useState({});
    let endDate = dayjs();
    let startDate = dayjs();
    // new Date(endDate.getFullYear(), endDate.getMonth(), 1);
    // endDate = moment(endDate).format('YYYY-MM-DD');
    // startDate = moment(startDate).format("YYYY-MM-DD");
    const [searchParams, setSearchParams] = useState({
        startDate,
        endDate
    });
    // const searchRecipientNumber = useDebounce(searchParams, 1000);
    const [reload, setReload] = useState(true);
    const [downloadData, setDownloadData] = useState()
    const [error, setError] = useState({ exists: false, message: "" });
    const state = useSelector(state => state.apiReportsManagementSlice)
    const dispatch = useDispatch();

    const getApiReportForBot = async (status) => {

        try {
            dispatch(setApiBotStatusReportLoading({ [status]: true }));
            const botId = selectedBot.config.rbmClientId
            const response = await apiGatewayAxiosInstance.get(`/conversation_logger/api-logger/get-api-reports-by-status-of-bot`, {
                params: {
                    messageStatus: status,
                    // messageStatus: searchParams.messageStatus,
                    userNumber: searchParams.userNumber,
                    startDate: moment(new Date(searchParams.startDate)).format('YYYY-MM-DD'),
                    endDate: moment(new Date(searchParams.endDate)).format('YYYY-MM-DD'),

                    botId,
                    // sendTime: "",
                    provider: searchParams.provider,
                },
            });


            dispatch(setApiBotStatusReport(response.data.apiBotReportDetails))
            // setApiBotStatusReport({ ...apiBotStatusReport, ...response.data.apiBotReportDetails })
        } catch (error) {
            console.log(error)

            dispatch(setApiBotStatusReport({ [status]: "--" }))
        }

        dispatch(setApiBotStatusReportLoading({ [status]: false }));

    }


    const getApiBotReportDetailsByPageSize = async (pageSize, currentPage) => {
        try {
            // setLoader(true)
            setApiBotReportListLoader(true)
            // const token = sessionStorage.getItem("accessToken");
            const botId = selectedBot.config.rbmClientId
            const response = await apiGatewayAxiosInstance.get(`/conversation_logger/api-logger/get-bot-reports-details-page-size-by-botId`, {

                params: {
                    // campaignId: campaigndetails._id,
                    pageSize,
                    currentPage: currentPage + 1,
                    messageStatus: searchParams.messageStatus,
                    userNumber: searchParams.userNumber,
                    startDate: moment(new Date(searchParams.startDate)).format('YYYY-MM-DD'),
                    templateName: searchParams.TemplateName,

                    endDate: moment(new Date(searchParams.endDate)).format('YYYY-MM-DD'),

                    botId,
                    // sendTime: "",
                    provider: searchParams.provider,
                },
            });

            setApiBotReportList(response.data.reportDetails)

            setTotalCountPageSize(response.data.totalCount);
        } catch (error) {
            //     let errorMessage = "Failed to get all customised rates.";

            //     if (error.response) {
            //         errorMessage = (error.response.data && error.response.data.message) ?? errorMessage;
            //     }
            //     setErrorResponse(errorMessage);
            // }
        }
        setApiBotReportListLoader(false)

    };

    useEffect(() => {
        dispatch(setApiBotStatusReport({
            "SENT": 0,
            "SMS": 0,
            "DELIVERED": 0,
            "RETRY": 0,
            "READ": 0,
            "TOTAL_COUNT": 0,
            "PENDING": 0,
            "FAILED": 0,
            "TTL_EXPIRATION_REVOKED": 0
        }))

        // getApiReportForBot(MessageStatusForCount.Pending);
        // getApiReportForBot(MessageStatusForCount.Sent);
        // getApiReportForBot(MessageStatusForCount.Delivered);
        // getApiReportForBot(MessageStatusForCount.Failed);
        // getApiReportForBot(MessageStatusForCount.Read);
        // getApiReportForBot(MessageStatusForCount.Retry);
        // getApiReportForBot(MessageStatusForCount.Sms);
        // getApiReportForBot(MessageStatusForCount.TotalCount);
        // getApiReportForBot(MessageStatusForCount.TTLExpirationRevoked);
    }, [])


    const onClickCancel = () => {
        setShowBotReport(false)
    }

    const onClickSearch = () => {
        setReload(!reload)
    }


    const handleClickDownloadReport = async () => {

        try {
            setApiBotReportListLoader(true)
            const botId = selectedBot.config.rbmClientId
            const data = await apiGatewayAxiosInstance.post(`/conversation_logger/api-logger/download-api-bot-report`,
                {
                    pageSize,
                    currentPage: currentPage + 1,
                    messageStatus: searchParams.messageStatus,
                    userNumber: searchParams.userNumber,
                    startDate: searchParams.startDate,
                    endDate: searchParams.endDate,
                    botId,
                    provider: searchParams.provider,
                });
            setDownloadData(data.data);
            if (!downloadData || downloadData.length === 0) return "";
            const headers = Object.keys(downloadData[0]);
            const csvRows = [];
            csvRows.push(headers.join(","));
            downloadData.forEach(obj => {
                const row = headers.map(header => obj[header] ?? "-");
                csvRows.push(row.join(","));
            });
            const csvString = csvRows.join("\n");
            const blob = new Blob([csvString], { type: "text/csv" });
            const url = URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.href = url;
            const documentName = `${String(selectedBot.botName).replace(" ", "_")}_API_REPORT_${moment().format("DD_MM_YYYY")}.csv`;
            link.download = documentName;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            URL.revokeObjectURL(url);
            setApiBotReportListLoader(false);
        } catch (error) {
            setApiBotReportListLoader(false);
            let errorMessage = "Failed to Download Bot API Report.";

            if (error.response) {
                errorMessage = (error.response.data && error.response.data.message) ?? errorMessage;
            }
            setError({ exists: true, message: errorMessage });
        }
    };


    const ViewApiBotSimulator = (apiBotDetails) => {

        setApiBotMessageDetails(apiBotDetails);
        setShowApiBotSimulator(true);
    }

    const handleChangeRowsPerPage = event => {
        setPageSize(parseInt(event.target.value));
        setCurrentPage(0);
        getApiBotReportDetailsByPageSize(parseInt(event.target.value), 0)

    };
    const handleChangePage = (event, newPage) => {
        setCurrentPage(newPage);
        getApiBotReportDetailsByPageSize(pageSize, newPage)
    };

    const handleChangeSearch = ({ name, value }) => {
        setSearchParams({ ...searchParams, [name]: value });
    }
    const onClickClear = () => {
        const endDateforClear = endDate;
        const startDateForClear = startDate;
        setSearchParams({
            TemplateName: "",
            messageStatus: "",
            provider: "",
            startDate: startDateForClear,
            endDate: endDateforClear,
            userNumber: ""
        });
        reloadList();
    };

    const reloadList = () => {
        setReload(!reload);
    };
    useEffect(() => {
        setCurrentPage(0);
        getApiBotReportDetailsByPageSize(pageSize, 0)
        getApiReportForBot(MessageStatusForCount.Pending);
        getApiReportForBot(MessageStatusForCount.Sent);
        getApiReportForBot(MessageStatusForCount.Delivered);
        getApiReportForBot(MessageStatusForCount.Failed);
        getApiReportForBot(MessageStatusForCount.Read);
        getApiReportForBot(MessageStatusForCount.Retry);
        getApiReportForBot(MessageStatusForCount.TotalCount);
        getApiReportForBot(MessageStatusForCount.TTLExpirationRevoked);
        getApiReportForBot(MessageStatusForCount.TotalSMSCount);
        getApiReportForBot(MessageStatusForCount.Sms);
        getApiReportForBot(MessageStatusForCount.SmsDELIVERED);
        getApiReportForBot(MessageStatusForCount.TotalSMSFailed);
    }, [reload])

    console.log(searchParams)
    const handleEndDateChange = (date) => {
        setSearchParams((prev) => ({ ...prev, endDate: date }));
    };

    const handleStartDateChange = (date) => {
        setSearchParams((prev) => ({ ...prev, startDate: date }));
    };
    return (


        <Grid>

            <Grid
                container
                className='listHeader'
                mb={2}
            >
                <Grid
                    item
                    md={6}
                    display={'flex'}
                    alignItems={'center'}
                >
                    <IconButton
                        color='secondary'
                        onClick={onClickCancel}
                        size='medium'
                    >
                        <ArrowBackIosIcon
                            sx={{ cursor: 'pointer' }}
                        />
                    </IconButton>
                    <Grid sx={{
                        display: "flex",
                        flexDirection: "row",
                    }}
                        gap={2}
                    >
                        <Grid
                            sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                        >
                            <Breadcrumbs aria-label="breadcrumb" fontSize="large">
                                <Link
                                    underline="hover"
                                    color="inherit"
                                    className='breadcrumbItem'
                                    // href="/rcs-management/manage-bots/"
                                    onClick={onClickCancel}
                                >
                                    API Report
                                </Link>
                                <Typography
                                    className='breadcrumbItem'
                                    color="text.primary"
                                >
                                    {selectedBot.botName} API Report
                                </Typography>
                            </Breadcrumbs>
                        </Grid>
                        <Grid
                            sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                        >
                            <IconButton
                                title='Refresh'
                                onClick={reloadList}
                                sx={{
                                    animation: apiBotReportListLoader ? "spin 0.5s linear infinite" : "",
                                    "@keyframes spin": {
                                        "0%": {
                                            transform: "rotate(360deg)",
                                        },
                                        "100%": {
                                            transform: "rotate(0deg)",
                                        },
                                    },
                                    // marginInline: "20px",
                                }}
                            >
                                <Cached />
                            </IconButton>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>



            <Card className='border border-secondary-50'>
                <CardContent>

                    <Grid sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        placeContent: "space-between"
                    }}>
                        <Grid sx={{ display: 'flex', gap: 1, margin: "10px" }}>
                            <Grid item maxWidth={"140px"} >

                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                        label="From Date"
                                        value={searchParams.startDate}
                                        format="DD-MM-YYYY"
                                        name="startDate"
                                        onChange={handleStartDateChange}
                                        renderInput={(params) => <TextField {...params} fullWidth />}
                                        disableFuture
                                        minDate={dayjs('2024-09-01')}
                                        maxDate={searchParams.endDate}
                                    />
                                </LocalizationProvider>
                                {/* <TextField
                                    fullWidth
                                    type="date"
                                    label="Start Date"
                                    name="startDate"
                                    value={searchParams.startDate}
                                    onChange={(e) => handleChangeSearch(e.target)}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                /> */}

                            </Grid>
                            <Grid item maxWidth={"140px"} >

                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                        label="To Date"
                                        value={searchParams.endDate}
                                        format="DD-MM-YYYY"
                                        name="endDate"
                                        onChange={handleEndDateChange}
                                        renderInput={(params) => <TextField {...params} fullWidth />}
                                        disableFuture
                                        minDate={searchParams.startDate}
                                        maxDate={dayjs()}
                                    />
                                </LocalizationProvider>
                                {/* <TextField
                                    fullWidth
                                    type="date"
                                    label="End Date"
                                    name="endDate"
                                    value={searchParams.endDate}
                                    onChange={(e) => handleChangeSearch(e.target)}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                /> */}
                            </Grid>

                            <Grid item >
                                <TextField
                                    value={searchParams.TemplateName}
                                    name="TemplateName"
                                    onChange={(e) => handleChangeSearch(e.target)}
                                    label="Template Name"
                                    fullWidth
                                    placeholder="Enter Template Name"
                                />
                            </Grid>
                            <Grid item >
                                <TextField
                                    value={searchParams.userNumber}
                                    name="userNumber"
                                    onChange={(e) => handleChangeSearch(e.target)}
                                    label="User Number"
                                    fullWidth
                                    placeholder="Enter User Number"
                                />
                            </Grid>



                            <Grid item >
                                <FormControl sx={{ minWidth: 80 }} >
                                    <InputLabel id="demo-controlled-open-select-label">
                                        Status
                                    </InputLabel>
                                    <Select

                                        labelId="demo-controlled-open-select-label"
                                        id="demo-controlled-open-select"
                                        name="messageStatus"
                                        value={searchParams.messageStatus ? `${searchParams.messageStatus}` : ""}
                                        label="Status"
                                        onChange={(e) => handleChangeSearch(e.target)}

                                    >
                                        {/* <MenuItem value={MessageStatus.Retry}>
                                            {MessageStatus.Retry}
                                        </MenuItem> */}
                                        <MenuItem value={MessageStatus.Failed}>
                                            {MessageStatus.Failed}
                                        </MenuItem>
                                        <MenuItem value={MessageStatus.Delivered}>
                                            {MessageStatus.Delivered}
                                        </MenuItem>
                                        <MenuItem value={MessageStatus.Pending}>
                                            {MessageStatus.Pending}
                                        </MenuItem>
                                        <MenuItem value={MessageStatus.Sent}>
                                            {MessageStatus.Sent}
                                        </MenuItem>
                                        <MenuItem value={MessageStatus.Read}>
                                            {MessageStatus.Read}
                                        </MenuItem>
                                        <MenuItem value={MessageStatus.TTLExpirationRevoked}>
                                            {MessageStatus.TTLExpirationRevoked}
                                        </MenuItem>
                                        <MenuItem value={MessageStatus.DELIVRD}>
                                            {getStatusToDisplay(MessageStatus.DELIVRD)}
                                        </MenuItem>
                                        <MenuItem value={MessageStatus.SMSFailed}>
                                            {getStatusToDisplay(MessageStatus.SMSFailed)}
                                        </MenuItem>
                                        {/* <MenuItem value={""}>All</MenuItem> */}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item >
                                <FormControl sx={{ minWidth: 100 }} >
                                    <InputLabel id="demo-simple-select-helper-label">
                                        Provider
                                    </InputLabel>
                                    <Select
                                        labelId="demo-simple-select-helper-label"

                                        id="demo-simple-select-helper"
                                        name="provider"
                                        value={searchParams.provider ? `${searchParams.provider}` : ""}
                                        label="Provider"
                                        onChange={(e) => handleChangeSearch(e.target)}

                                    >
                                        {["VI", "SMS"].map((provider) => (
                                            <MenuItem key={provider} value={provider}>
                                                {provider}
                                            </MenuItem>
                                        ))}
                                        {/* <MenuItem key={"all"} value={""}>All</MenuItem> */}
                                    </Select>
                                </FormControl>
                            </Grid>

                            {searchParams.startDate > searchParams.endDate ? (
                                <BootstrapTooltip title="Start Date cannot be greater than End Date!">
                                    <Grid item sx={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center"
                                    }}>
                                        <Button
                                            onClick={() => onClickSearch()}
                                            aria-label='sent'
                                            color='primary'
                                            variant='contained'
                                            disabled={true}
                                        >
                                            Search {apiBotReportListLoader && <CircularProgress sx={{ marginLeft: '.3rem' }} color="inherit" size={15} />}
                                        </Button>
                                    </Grid>

                                </BootstrapTooltip>
                            ) : (
                                <Grid item sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center"
                                }}>
                                    <Button
                                        onClick={() => onClickSearch()}
                                        aria-label='sent'
                                        color='primary'
                                        variant='contained'
                                        disabled={apiBotReportListLoader}
                                    >
                                        Search {apiBotReportListLoader && <CircularProgress sx={{ marginLeft: '.3rem' }} color="inherit" size={15} />}
                                    </Button>
                                </Grid>)}

                            <Grid item sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center"
                            }}>
                                <Button
                                    onClick={onClickClear}
                                    aria-label='sent'
                                    variant="contained"
                                    color="error"
                                    disabled={apiBotReportListLoader}
                                >
                                    Clear
                                </Button>
                            </Grid>
                        </Grid>
                        {/* <Grid
                            sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                        >
                            <Button
                                sx={{
                                    marginRight: "10px",
                                    whiteSpace: "nowrap"
                                }}
                                // variant="outlined"
                                onClick={handleClickDownloadReport}
                            ><Download />
                                &nbsp; Download Report
                            </Button>
                        </Grid> */}

                    </Grid>
                    <Grid
                        container
                        md={12}
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            flexWrap: "wrap !important"
                        }}
                        gap={5}
                    >
                        RCS :
                        <Grid
                            item

                        >
                            <Grid
                                item
                                md={12}
                                sx={{
                                    display: "flex",
                                    // justifyContent: "space-between",
                                    alignItems: "center",
                                    flexWrap: "wrap !important"
                                }}
                                gap={5}
                            >
                                <Grid>
                                    <Typography
                                        sx={{
                                            fontSize: "0.7rem !important",
                                            fontWeight: "bold",
                                        }}
                                    >
                                        <Grid display='flex' flexDirection={'row'}>
                                            TOTAL :   {state.apiBotStatusReportLoading[MessageStatusForCount.TotalCount] ? <CircularProgress sx={{ marginLeft: '.3rem' }} color="inherit" size={15} /> : state.apiBotStatusReport[MessageStatusForCount.TotalCount]}
                                        </Grid>
                                    </Typography>
                                </Grid>
                                <Grid
                                    sx={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center !important",
                                    }}
                                >
                                    <Typography
                                        color='orange'
                                        sx={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center !important",
                                            fontSize: "0.7rem !important",
                                            fontWeight: "bold",
                                        }}
                                    >

                                        < AccessTimeIcon fontSize='small' sx={{ margin: "5px" }} />
                                        PENDING : {state.apiBotStatusReportLoading[MessageStatusForCount.Pending] ? <CircularProgress sx={{ marginLeft: '.3rem' }} color="inherit" size={15} /> : state.apiBotStatusReport[MessageStatusForCount.Pending]}


                                    </Typography>
                                </Grid>
                                <Grid>
                                    <Typography
                                        color='primary'
                                        sx={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center !important",
                                            fontSize: "0.7rem !important",
                                            fontWeight: "bold",
                                        }}
                                    >
                                        {/* <DoneIcon fontSize="small" sx={{ margin: "5px" }} /> */}

                                        <RcsSentIcon />

                                        SENT : {state.apiBotStatusReportLoading[MessageStatusForCount.Sent] ? <CircularProgress sx={{ marginLeft: '.3rem' }} color="inherit" size={15} /> : state.apiBotStatusReport[MessageStatusForCount.Sent]}
                                    </Typography>
                                </Grid>

                                <Grid>
                                    <Typography
                                        color='green'
                                        sx={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center !important",
                                            fontSize: "0.7rem !important",
                                            fontWeight: "bold",
                                        }}
                                    >
                                        {/* <DoneAllIcon fontSize="small" sx={{ margin: "5px" }} /> */}
                                        <RcsDeliveredIcon />
                                        DELIVERED : {state.apiBotStatusReportLoading[MessageStatusForCount.Delivered] ? <CircularProgress sx={{ marginLeft: '.3rem' }} color="inherit" size={15} /> : state.apiBotStatusReport[MessageStatusForCount.Delivered]}
                                        {/* {
                                    campaignReports.filter(
                                        (campaignReport) =>
                                            campaignReport.statusWithTime['DELIVERED']
                                    ).length
                                } */}
                                    </Typography>
                                </Grid>

                                <Grid>
                                    <Typography
                                        color='darkblue'
                                        sx={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center !important",
                                            fontSize: "0.7rem !important",
                                            fontWeight: "bold",
                                        }}
                                    >
                                        {/* <RemoveRedEyeIcon fontSize="small" sx={{ margin: "5px" }} /> */}
                                        <RcsReadIcon />
                                        READ :{state.apiBotStatusReportLoading[MessageStatusForCount.Read] ? <CircularProgress sx={{ marginLeft: '.3rem' }} color="inherit" size={15} /> : state.apiBotStatusReport[MessageStatusForCount.Read]}

                                    </Typography>
                                </Grid>
                                <Grid>
                                    <Typography
                                        color='error'
                                        sx={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center !important",
                                            fontSize: "0.7rem !important",
                                            fontWeight: "bold",
                                        }}
                                    >
                                        <UnpublishedIcon fontSize='small' sx={{ margin: "5px" }} />
                                        FAILED : {state.apiBotStatusReportLoading[MessageStatusForCount.Failed] ? <CircularProgress sx={{ marginLeft: '.3rem' }} color="inherit" size={15} /> : state.apiBotStatusReport[MessageStatusForCount.Failed]}

                                    </Typography>
                                </Grid>
                                <Grid>
                                    <Typography
                                        color='GrayText'
                                        sx={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center !important",
                                            fontSize: "0.7rem !important",
                                            fontWeight: "bold",
                                        }}
                                    >
                                        <CurrencyExchangeIcon fontSize='small' sx={{ margin: "5px" }} />
                                        Sent Revoked : {state.apiBotStatusReportLoading[MessageStatusForCount.TTLExpirationRevoked] ? <CircularProgress sx={{ marginLeft: '.3rem' }} color="inherit" size={15} /> : state.apiBotStatusReport[MessageStatusForCount.TTLExpirationRevoked]}

                                    </Typography>
                                </Grid>
                            </Grid>


                            {/* ====================================================== sms =======================================================  */}


                        </Grid>

                    </Grid>
                    <Grid
                        item
                        md={12}
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            flexWrap: "wrap !important"
                        }}
                        gap={5}
                    >
                        SMS :
                        <Grid>
                            <Typography
                                sx={{
                                    fontSize: "0.7rem !important",
                                    fontWeight: "bold",
                                }}
                            >
                                <Grid display='flex' flexDirection={'row'}>
                                    TOTAL :   {state.apiBotStatusReportLoading[MessageStatusForCount.TotalSMSCount] ? <CircularProgress sx={{ marginLeft: '.3rem' }} color="inherit" size={15} /> : state.apiBotStatusReport[MessageStatusForCount.TotalSMSCount]}
                                </Grid>
                            </Typography>
                        </Grid>
                        <Grid>
                            <Typography
                                color='primary'
                                sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center !important",
                                    fontSize: "0.7rem !important",
                                    fontWeight: "bold",
                                }}
                            >
                                <RcsSentIcon />

                                SENT : {state.apiBotStatusReportLoading[MessageStatusForCount.Sms] ? <CircularProgress sx={{ marginLeft: '.3rem' }} color="inherit" size={15} /> : state.apiBotStatusReport[MessageStatusForCount.Sms]}

                            </Typography>
                        </Grid>

                        <Grid>
                            <Typography
                                color='green'
                                sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center !important",
                                    fontSize: "0.7rem !important",
                                    fontWeight: "bold",
                                }}
                            >
                                <RcsDeliveredIcon />
                                DELIVERED : {state.apiBotStatusReportLoading[MessageStatusForCount.SmsDELIVERED] ? <CircularProgress sx={{ marginLeft: '.3rem' }} color="inherit" size={15} /> : state.apiBotStatusReport[MessageStatusForCount.SmsDELIVERED]}
                            </Typography>
                        </Grid>


                        <Grid>
                            <Typography
                                color='error'
                                sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center !important",
                                    fontSize: "0.7rem !important",
                                    fontWeight: "bold",
                                }}
                            >
                                <UnpublishedIcon fontSize='small' sx={{ margin: "5px" }} />
                                Failed : {state.apiBotStatusReportLoading[MessageStatusForCount.TotalSMSFailed] ? <CircularProgress sx={{ marginLeft: '.3rem' }} color="inherit" size={15} /> : state.apiBotStatusReport[MessageStatusForCount.TotalSMSFailed]}

                            </Typography>
                        </Grid>
                    </Grid>


                    {
                        apiBotReportListLoader ? (
                            <FetchingSpinner />
                        ) :
                            apiBotReportList && apiBotReportList.length > 0 ? (
                                <>
                                    <TableContainer component={Paper} className='table-container' sx={{ borderRadius: "10px  !important", maxHeight: "60vh" }}  >
                                        <Table stickyHeader sx={{}}>
                                            <TableHead className='row'>
                                                <TableRow>
                                                    <StyledTableCell className='tableContentFont' align='center'>
                                                        Sr No
                                                    </StyledTableCell>
                                                    <StyledTableCell className='tableContentFont' align='center'>
                                                        <Typography variant>Users</Typography>
                                                    </StyledTableCell>

                                                    <StyledTableCell className='tableContentFont' align='center'>
                                                        Send Time
                                                    </StyledTableCell>
                                                    <StyledTableCell className='tableContentFont' align='center'>
                                                        Provider
                                                    </StyledTableCell>
                                                    <StyledTableCell className='tableContentFont' align='center'>
                                                        Reason
                                                    </StyledTableCell>

                                                    <StyledTableCell className='tableContentFont' align='center'>
                                                        Message Status
                                                    </StyledTableCell>
                                                    <StyledTableCell className='tableContentFont' align='center'>
                                                        Actions
                                                    </StyledTableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {/* {campaignReports.slice(currentPage * pageSize, currentPage * pageSize + pageSize).map((campaignReport, index) => ( */}
                                                {apiBotReportList.map((apiBotReport, index) => (

                                                    <StyledTableRow key={index}>
                                                        <StyledTableCell className='tableContentFont' align='center'>
                                                            <b>{currentPage * pageSize + index + 1}</b>
                                                        </StyledTableCell>
                                                        <StyledTableCell className='tableContentFont' align='center'>
                                                            {apiBotReport?.receiverNumber}
                                                        </StyledTableCell>
                                                        <StyledTableCell className='tableContentFont' align='center'>
                                                            {calculateTime(apiBotReport?.sendTime)}
                                                        </StyledTableCell>
                                                        <StyledTableCell className='tableContentFont' align='center'>
                                                            {apiBotReport?.provider && (
                                                                <>
                                                                    {apiBotReport?.provider.toLowerCase() === "vi" && (
                                                                        <img src={RCSLogo} height={"20px"} width={"20px"} title={apiBotReport?.provider} />
                                                                    )}
                                                                    {apiBotReport?.provider.toLowerCase() === "sms" && (
                                                                        <img src={SmsLogo} height={"20px"} width={"20px"} title={apiBotReport?.provider} />
                                                                    )}
                                                                </>
                                                            )}
                                                        </StyledTableCell>

                                                        <StyledTableCell className='tableContentFont' align='center'>
                                                            {/* {apiBotReport?.reason ?? "-"} */}

                                                            <Tooltip
                                                                title={<p style={{ fontSize: ".7rem" }} >{apiBotReport?.reason} </p>}
                                                                className='cursor-pointer'
                                                            >
                                                                {apiBotReport?.reason ? apiBotReport?.reason.length > 75 ? `${apiBotReport?.reason.substr(0, 75)}...` : apiBotReport?.reason : "-"}
                                                            </Tooltip>

                                                        </StyledTableCell>



                                                        <StyledTableCell className='tableContentFont' align='center'>
                                                            {/* {campaignReport?.statusWithTime && messageLastStatus(campaignReport?.statusWithTime)} */}
                                                            <Typography
                                                                variant='subtitle2'
                                                                color={
                                                                    getColourByMessageStatus(apiBotReport?.statusWithTime)
                                                                }>

                                                                {messageLastStatus(apiBotReport?.statusWithTime)}
                                                                {/* <br />
                                                    {campaignReport.messageStatus} */}
                                                            </Typography>
                                                        </StyledTableCell>

                                                        <StyledTableCell className='tableContentFont' align='center'>
                                                            <Grid
                                                                sx={{
                                                                    display: "flex",
                                                                    alignItems: "center",
                                                                    justifyContent: "center",
                                                                }}
                                                            >

                                                                {apiBotReport.messagePayload &&
                                                                    <IconButton title='View Message' onClick={() => ViewApiBotSimulator(apiBotReport)}>
                                                                        <AodIcon />
                                                                    </IconButton>
                                                                }

                                                                {apiBotReport?.statusWithTime && (
                                                                    <Tooltip
                                                                        sx={{
                                                                            paddingLeft: 2,
                                                                        }}
                                                                        title={
                                                                            <p
                                                                                style={{
                                                                                    fontSize: ".7rem",
                                                                                }}
                                                                            >
                                                                                {Object.values(MessageStatus).map(status => {
                                                                                    return (
                                                                                        <>
                                                                                            {getStatusToDisplay(status)} :{" "}
                                                                                            {
                                                                                                apiBotReport?.statusWithTime[status]
                                                                                                    ? calculateTime(apiBotReport?.statusWithTime[status])
                                                                                                    : "-"
                                                                                            }
                                                                                            <br />
                                                                                        </>
                                                                                    );
                                                                                })}
                                                                            </p>
                                                                        }
                                                                        className='cursor-pointer'
                                                                    >
                                                                        <InfoOutlinedIcon
                                                                            sx={{
                                                                                opacity: "50%",
                                                                            }}
                                                                        />
                                                                    </Tooltip>
                                                                )}
                                                            </Grid>
                                                        </StyledTableCell>
                                                    </StyledTableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </>
                            ) : (
                                <NoRecords />
                            )
                    }


                    {apiBotReportList.length > 0 && (
                        <Grid
                            className='my-2'
                            style={{
                                zIndex: "2",
                                display: "flex",
                                justifyContent: "space-between",
                            }}
                        >
                            <Grid
                                style={{
                                    marginTop: "15px",
                                }}
                            >
                                Total Records: {totalCountPageSize}
                            </Grid>
                            <TablePaginationShowPage
                                rowsPerPageOptions={[5, 10, 25, 100]}
                                colSpan={3}
                                count={totalCountPageSize}
                                rowsPerPage={pageSize}
                                page={currentPage}
                                slotProps={{
                                    select: {
                                        "aria-label": "Rows per page",
                                    },
                                    actions: {
                                        showFirstButton: true,
                                        showLastButton: true,
                                        slots: {
                                            firstPageIcon: FirstPageRoundedIcon,
                                            lastPageIcon: LastPageRoundedIcon,
                                            nextPageIcon: ChevronRightRoundedIcon,
                                            backPageIcon: ChevronLeftRoundedIcon,
                                        },
                                    },
                                }}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </Grid>
                    )}
                </CardContent>
            </Card>

            {showApiBotSimulator && (
                <CampaignSimulator
                    open={showApiBotSimulator}
                    setShowCampaignSimulator={setShowApiBotSimulator}
                    campaignDetails={apiBotMessageDetails}
                    campaignData={{ otherDetails: { botDetails: selectedBot } }}
                    contentMessage={apiBotMessageDetails.messagePayload.payload.contentMessage}
                />
            )}
            {error.exists &&
                (
                    <AlertMessageComponent
                        message={error.message}
                        fieldName={"Download Report"}
                        handleClose={() => setError({ exists: false, message: "" })}
                        show={error.exists}
                    />
                )}
        </Grid>
    )
}
