import { Box, Button, Card, Divider, Grid, IconButton, Paper, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import GppGoodIcon from '@mui/icons-material/GppGood';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import TextMessageComponent from "./TextMessageComponent";
import RichCardStandAloneDetailsComponent from "./RichCardStandAloneDetailsComponent";
import RichCardCarouselDetailsComponent from "./RichCardCarouselDetailsComponent";
import { useSelector } from "react-redux";;




export default function SimulatorComponent({ formik, exportForTemplatePreviewRef }) {

    const [botName, setBotName] = useState('')

    const botState = useSelector((state) => state.botManagementSlice);


    // const exportForTemplatePreviewRef = useRef();



    useEffect(() => {
        if (formik.values.botId && botState.verifiedBotList && botState.verifiedBotList.length > 0) {

            setBotName(botState.verifiedBotList.find((bot) => bot._id === formik.values.botId)?.botName)

        }
    }, [formik.values.botId])



    //------------------------------------------------------------------------------------------------------------




    return (

        <Grid

            paddingX={2}
            md={3}
            item
            sx={{
                boxShadow:
                    "box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;",
            }}
        >
            <Paper
                ref={exportForTemplatePreviewRef}
                elevation={5}
                sx={{
                    minHeight: "82vh",
                    position: "fixed",
                    width: "22%",
                    borderRadius: "50px",
                    height: "80vh",
                    maxHeight: "80vh",
                    border: "2px solid grey",
                }}
            >
                <Grid
                    container
                    display={"flex"}
                    flexDirection={"column"}
                    justifyContent={"center"}
                    alignItems={"center"}
                    width={"100%"}
                >
                    {/* Simulator Header */}
                    <Grid
                        item
                        md={0.5}
                        height={"8vh"}
                        minHeight={"8vh"}
                        display={"flex"}
                        justifyContent={"center"}
                        alignItems={"center"}
                        minWidth={"100%"}
                        borderBottom={"1px solid grey"}
                        paddingLeft={5}
                    >
                        <Grid
                            item
                            minWidth={"6rem"}
                            width={"6rem"}
                            height={"13px"}
                            sx={{
                                borderRadius: "50px",
                                border: "2px solid grey",
                                marginRight: 4,
                            }}
                        ></Grid>
                        <Grid
                            item
                            minWidth={"15px"}
                            height={"15px"}
                            sx={{
                                borderRadius: "100px",
                                border: "2px solid grey",
                            }}
                        ></Grid>
                    </Grid>
                    {/* Simulator Body */}
                    <Grid
                        item
                        md={11}
                        height={"66vh"}
                        minHeight={"66vh"}
                        maxHeight={"66vh"}
                        width={"100%"}
                        minWidth={"100%"}
                        sx={{ overflow: "scroll" }}

                    >
                        <Box sx={{ margin: 1, border: "2px solid Grey", minHeight: "65vh", overflow: "hidden" }}>
                            <Grid container sx={{ minHeight: "4vh", padding: 1, display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <Grid itme md={2} ><ArrowBackIcon /></Grid>
                                <Grid itme md={8} >
                                    <Typography variant="body1" sx={{ fontWeight: "bold" }}>

                                        {botName}
                                    </Typography>
                                </Grid>
                                <Grid itme md={2}><GppGoodIcon /><MoreVertIcon /></Grid>

                            </Grid>
                            <Divider />

                            <Grid container fullWidth >


                                {/* -----------------------------------------------------text_message----------------------------------------------------- */}
                                {formik.values.templateType === "text_message" && <TextMessageComponent textMessageDetails={formik.values.textMessageDetails} />}

                                {/* ----------------------------------------------richCardStandAloneDetails----------------------------------------------------- */}
                                {formik.values.templateType === "rich_card" && <RichCardStandAloneDetailsComponent richCardStandAloneDetails={formik.values.richCardStandAloneDetails} />}


                                {/* ----------------------------------------------richCardCarouselDetails------------------------------------------ */}
                                {formik.values.templateType === "carousel" && <RichCardCarouselDetailsComponent richCardCarouselDetails={formik.values.richCardCarouselDetails} />}
                            </Grid>
                        </Box>
                    </Grid>
                    {/* Simulator Footer */}
                    <Grid
                        item
                        md={0.5}
                        height={"8vh"}
                        minHeight={"8vh"}
                        display={"flex"}
                        justifyContent={"center"}
                        alignItems={"center"}
                        borderTop={"1px solid grey"}
                        minWidth={"100%"}
                    >
                        <Grid
                            item
                            minWidth={"4rem"}
                            width={"4rem"}
                            height={"25px"}
                            sx={{
                                borderRadius: "50px",
                                border: "2px solid grey",
                            }}
                        ></Grid>
                    </Grid>
                </Grid >
            </Paper >
        </Grid >

    );
}
