import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { useDispatch } from "react-redux";
import { changeBotStatusThunk } from "../../redux/botManagement/botManagementThunk";
import { FormControl, FormHelperText, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { BotStatus } from "../../config";
import { select } from "slate";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction='up' ref={ref} {...props} />;
});

export default function BotStatusUpdate({ open, setOpen, data, setBotStatusData }) {
    const dispatch = useDispatch();
    const [status, setStatus] = React.useState("");
    const [statusError, setStatusError] = React.useState("");

    const [botStatusDetails, setBotStatusDetails] = React.useState(data);

    const handleClose = () => {
        setOpen(false);
    };

    const UpdateStatus = () => {
        if (status) {

            dispatch(changeBotStatusThunk({ data: botStatusDetails }));
            handleClose();
        } else {
            setStatusError("select");
        }
    };

    const onStatusChange = event => {
        setStatusError("");
        setStatus(event.target.value);
        setBotStatusDetails({ ...botStatusDetails, status: event.target.value });
    };


    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            aria-describedby='alert-dialog-slide-description'
            fullWidth
            maxWidth={"sm"}
        // style={{width:"1000px"}}
        >
            <DialogTitle>{"Update Bot Status"}</DialogTitle>
            <DialogContent>
                <DialogContentText id='alert-dialog-slide-description'>
                    {/* Change Bot Status To {data.status} */}
                    <FormControl fullWidth className="mt-3" error={statusError}>
                        <InputLabel id='demo-simple-select-helper-label'>Change Status to</InputLabel>
                        <Select
                            fullWidth
                            // disabled
                            // value={status}
                            onChange={onStatusChange}
                            label='Change Status to'
                            labelId='demo-simple-select-helper-label'
                            id='demo-simple-select'
                            MenuProps={{
                                PaperProps: {
                                    sx: {
                                        boxShadow: " 0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1)",
                                    },
                                },
                            }}
                        >
                            {BotStatus &&
                                Object.values(BotStatus).map((item, index) => (
                                    <MenuItem
                                        key={index}
                                        value={item}
                                    // disabled={
                                    //     data.status === BotStatus.New && item !== BotStatus.BotCreationRejected
                                    //         ? true
                                    //         : data.status === BotStatus.VerificationSubmitted && item !== BotStatus.VerificationInProcess
                                    //             ? true
                                    //             : data.status === BotStatus.VerificationInProcess &&
                                    //                 item !== BotStatus.Verified &&
                                    //                 item !== BotStatus.VerificationFailed
                                    //                 ? true
                                    //                 : data.status === BotStatus.Verified && item !== BotStatus.LaunchingInProcess
                                    //                     ? true
                                    //                     : data.status === BotStatus.VerificationFailed && item !== BotStatus.VerificationSubmitted
                                    //                         ? true
                                    //                         : data.status === BotStatus.LaunchingInProcess &&
                                    //                             item !== BotStatus.Launched &&
                                    //                             item !== BotStatus.LaunchFailed
                                    //                             ? true
                                    //                             : data.status === BotStatus.LaunchFailed && true
                                    // }
                                    >
                                        {item.replaceAll("_", " ").toLocaleUpperCase()}
                                    </MenuItem>
                                ))}
                            {/* <MenuItem value={ClientStatus.InActive}>InActive</MenuItem> */}
                        </Select>
                        <FormHelperText>{statusError}</FormHelperText>

                    </FormControl>

                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button onClick={UpdateStatus}>Update</Button>
            </DialogActions>
        </Dialog>
    );
}
